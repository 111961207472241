import { CollectionResponse } from './../models/collectionResponse.model';
import { ApiDependenciesService } from './../../api/services/api-dependencies.service';
import 'rxjs/add/operator/toPromise';
import { ApiEntity } from '../../api/models/api-entity.model';
import { BaseApiService } from '../../api/services/base-api.service';
import { Base64Image } from 'app/shared/models/base64-image.model';
import { ExtendedBlob } from 'app/shared/models/extended-blob';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiResponseSummary } from '../models/api-response-summary.model';

export abstract class DataService<T extends ApiEntity> extends BaseApiService {
  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  public async queryCollection(queryParams: any = {}): Promise<CollectionResponse<T>> {
    let result: CollectionResponse<T> = null;
    const request = this.dependencies.http.post(this.buildUrl(), queryParams, { headers: this.buildHeaders() }).toPromise();

    await request
      .then((response: Response) => (result = this.parseDataQueryCollectionResponse(response)))
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async getCollection(): Promise<T[]> {
    let result: T[] = null;
    const request = this.dependencies.http.get(this.buildUrl(), { headers: this.buildHeaders() }).toPromise();

    await request
      .then((response: Response) => (result = this.parseCollectionResponse(response)))
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async getSingle(): Promise<T> {
    let result: T = null;
    const url = this.buildUrl();
    const headers = this.buildHeaders();
    const request = this.dependencies.http.get(url, { headers: headers }).toPromise();

    await request
      .then((response: Response) => {
        result = this.parseSingleResponse(response);
      })
      .catch(error => {
        this.handleApiError(error, request);
      });

    return Promise.resolve(result);
  }

  public async getSingleNoThrow(): Promise<T> {
    let result: T = null;
    const url = this.buildUrl();
    const headers = this.buildHeaders();
    const request = this.dependencies.http.get(url, { headers: headers }).toPromise();

    await request
      .then((response: Response) => {
        result = this.parseSingleResponse(response);
      })
      .catch(error => {
        result = null;
      });

    return Promise.resolve(result);
  }

  public async getFileAsBase64(): Promise<Base64Image> {
    const request: Promise<Object> = this.dependencies.http
      .get(this.buildUrl(), { headers: this.buildHeaders(), responseType: 'blob' })
      .toPromise();

    return request
      .then((response: Response) => {
        return new Promise((resolve, reject) => {
          if (response.status === 204) {
            resolve(null);
          }
          const fileReader = new FileReader();
          let fileBlob;
          Promise.resolve(response.blob().then(myBlob => (fileBlob = myBlob)));

          let blob = new Blob([fileBlob]);
          fileReader.readAsDataURL(blob);
          fileReader.onloadend = () => {
            const image = new Image();
            image.onload = () => {
              // TODO: [Technical Debt][dpb][2021-04-22] Fix for Angular 11 upgrade
              //resolve({ base64: fileReader.result, width: image.width, height: image.height });
            };
          };
        });
      })
      // TODO: [Technical Debt][dpb][2021-04-22] Fix for Angular 11 upgrade
      //.catch((error) => this.handleApiError(error, request));
    }

  public async getFileAsBlob(): Promise<Blob> {
    const result: Blob = null;
    const request: Promise<Object> = this.dependencies.http
      .get(this.buildUrl(), { headers: this.buildHeaders(), responseType: 'blob' })
      .toPromise();

    return request
      .then((response: Response) => {
        return new Promise((resolve, reject) => {
          if (response.status === 204) {
            resolve(null);
          }
          resolve(response.blob());
        });
      })
      // TODO: [Technical Debt][dpb][2021-04-22] Fix for Angular 11 upgrade
      //.catch((error) => this.handleApiError(error, request));
    }

  public async getFileAsExtendedBlob(): Promise<ExtendedBlob> {
    const result: ExtendedBlob = new ExtendedBlob();
    const request: Promise<Object> = this.dependencies.http
      .get(this.buildUrl(), { headers: this.buildHeaders(), responseType: 'blob' })
      .toPromise();

    return request
      .then((response: Response) => {
        result.metaData = this.getFileNameFromHttpResponse(response);
        Promise.resolve(response.blob().then(blob => (result.blob = blob)));
        return new Promise((resolve, reject) => {
          if (response.status === 204) {
            resolve(null);
          }
          resolve(result);
        });
      })
      // TODO: [Technical Debt][dpb][2021-04-22] Fix for Angular 11 upgrade
      //.catch((error) => this.handleApiError(error, request));
    }

  private getFileNameFromHttpResponse(httpResponse) {
    var contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    var result = contentDispositionHeader
      .split(';')[1]
      .trim()
      .split('=')[1];
    return result.replace(/"/g, '');
  }

  //todo: rename this with better name get/post
  public async getFileAsBlobWithPost(postData: any): Promise<ExtendedBlob> {
    const result: ExtendedBlob = new ExtendedBlob();
    const request: Promise<Object> = this.dependencies.http
      .post(this.buildUrl(), postData, { headers: this.buildHeaders(), responseType: 'blob', observe: 'response' as 'body' })
      .toPromise();

    return request
      .then((response: any) => {
        result.blob = response.body;

        result.metaData = this.getFileNameFromHttpResponse(response);
        return new Promise((resolve, reject) => {
          if (response.status === 204) {
            resolve(null);
          }
          resolve(result);
        });
      })
      // TODO: [Technical Debt][dpb][2021-04-22] Fix for Angular 11 upgrade
      //.catch((error) => this.handleApiError(error, request));
    }

  public async sendFileAsBlob(arrayBuffer: ArrayBuffer, fileExtension: string): Promise<boolean> {
    const url = this.buildUrl();
    const headers = this.buildHeaders();
    const ok = await this.upload(url, headers, arrayBuffer, fileExtension);
    return Promise.resolve(ok);
  }

  // TODO: [Review][DPB][2019-08-21] This looks like a hangove from Watson homes. Can it be resused or should it be scrapped?
  private upload(url: string, headers: HttpHeaders, arrayBuffer: ArrayBuffer, fileExtension: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 204) {
            resolve(true);
          } else {
            reject(false);
          }
        }
      };

      xhr.open('POST', url, true);

      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('X-API-Version', '1');
      xhr.setRequestHeader('Authorization', headers.get('Authorization'));
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      const file = new File([arrayBuffer], 'evidence' + fileExtension);
      const formData = new FormData();
      formData.append('EvidenceFile', file);

      xhr.send(formData);
    });
  }
  // Obsolete??
  public async getById(id: number): Promise<T> {
    let result: T = null;

    const request = this.dependencies.http.get(this.buildUrl(), { headers: this.buildHeaders() }).toPromise();

    await request
      .then((response: Response) => (result = this.parseSingleResponse(response)))
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async save(item: any): Promise<T[]> {
    let result: T[] = null;

    const request = this.dependencies.http.post(this.buildUrl(), item, { headers: this.buildHeaders() }).toPromise();

    await request
      .then((response: Response) => (result = this.parseCollectionResponse(response)))
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async update(item: any): Promise<ApiResponseSummary<T>> {
    let result: ApiResponseSummary<T>;

    const url = this.buildUrl();
    const headers = this.buildHeaders();
    const request = this.dependencies.http.put(url, item, { headers: headers }).toPromise();

    await request
      .then((response: Response) => {
        result = {success: true, message: '', data: null}

        if (response) {
          result.data = this.parseCollectionResponse(response);
        };
      })
      .catch(error => {
        if (error) {
          if (error.status === 409) {
            const message = error.error.errors[1];
            result = { success: false, message: message, data: null };
          } else {
            this.handleApiError(error, request);
          }
        }
      });

    return Promise.resolve(result);
  }

  public async command(data: any = null): Promise<T[]> {
    let result: T[] = null;

    const request = this.dependencies.http.post(this.buildUrl(), data, { headers: this.buildHeaders() }).toPromise();

    await request
      .then((response: Response) => (result = this.parseCollectionResponse(response)))
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async delete(id: number): Promise<boolean> {
    let result = false;

    const request = this.dependencies.http.delete(this.buildUrl(), { headers: this.buildHeaders(), observe: 'response' }).toPromise();
    await request
      .then((response: HttpResponse<any>) => {
        result = response.status === 200;
      }) // TODO improve this check to be a standardized ServiceAction response type to check validity or errors
      .catch(error => this.handleApiError(error, request));

    return Promise.resolve(result);
  }

  public async isOnline(): Promise<boolean> {
    let isOnline = false;
    await this.setEndpoint('/offline/testIsOnline')
      .getSingleNoThrow()
      .then(result => {
        if (result) {
          isOnline = true;
        } else {
          isOnline = false;
        }
      })
      .catch(err => {
        isOnline = false;
      });

    return Promise.resolve(isOnline);
  }

  private parseSingleResponse(response: Response): T {
    let result: T;

    if (response === null) {
      return null;
    }

    if (response['success'] === true) {
      if (response.hasOwnProperty('data')) {
        result = response['data'] as T;
      }
    }
    this.resetEndpointFromCache();
    return result;
  }

  private parseCollectionResponse(response: Response): T[] {
    let result: T[] = [];
    if (response === null) return null;

    if (response['success'] === true) {
      if (response.hasOwnProperty('data')) {
        result = response['data'] as T[];
      }
    }
    this.resetEndpointFromCache();
    return result;
  }

  private parseDataQueryCollectionResponse(response: Response): CollectionResponse<T> {
    if (response === null) return null;
    const result = new CollectionResponse<T>(0, 0, 0, null);
    if (response['success'] === true) {
      result.items = [];
      if (response !== null && response.hasOwnProperty('data')) {
        if (response['data'] !== null && response['data']['items'] !== null) {
          result.items = response['data']['items'] as T[];
          result.itemsCount = response['data'].itemCount;
          result.pages = response['data'].pageCount;
          result.page = response['data'].pageNumber;
        }
      }
    }

    this.resetEndpointFromCache();
    return result;
  }
}
