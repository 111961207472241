import { Injectable } from '@angular/core';
import {
  Router,
  Route,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  NavigationExtras
} from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AuthService } from 'app/core/security/auth/auth.service';

@Injectable()
export class AppAuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router) {}

  // Synchronous guard
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    const anonymousCheckRoute = route.children[0].children[0];
    if (anonymousCheckRoute && anonymousCheckRoute.data && anonymousCheckRoute.data.allowAnonymous && anonymousCheckRoute.data.allowAnonymous === true) {
      return true;
    }

    return this.checkLogin(url);
  }

  // Synchronous child guard
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  private checkLogin(url: string): boolean {
    if (this.auth.isLoggedIn()) {
      console.debug('already logged in');
      return true;
    }

    console.debug('setting redirect url from auth guard');
    console.debug(url);
    this.auth.setRedirectUrl(url);

    this.router.navigate(['/login']);
    return true;
  }
}
