import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from 'app/core/security/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthResponse } from '../api/auth-response.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CookieService } from 'app/shared/services/cookie.service';
import { environment } from 'environments/environment';
import { ApplicationService } from 'app/shared/services/application.service';
import { ApiInfo } from 'app/shared/models/api-info.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '../../../../../node_modules/@angular/forms';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';

// TODO move to shared
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @BlockUI('login') blockUI: NgBlockUI;
  isError: boolean;
  serverErrorMessage: string;
  loginInfo: string;
  appVersion = 'unknown build number';
  apiInfo: ApiInfo;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: ApplicationService,
    // private permissionsService: PermissionsService,
    private cookieService: CookieService,
    private formHelperService: ReactiveFormHelperService,
    private vcr: ViewContainerRef
  ) {
    this.dataForm = fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  dataForm: FormGroup;

  ngOnInit() {
    this.appVersion = environment.buildLabel;

    this.appService.getBuildVersion().then((apiInfo: ApiInfo) => {
      this.apiInfo = apiInfo;
    });

    let autoLoginGuid;
    this.route.params.subscribe((params) => {
      autoLoginGuid = params['uniqueIdentifier'];
    });

    if (autoLoginGuid) {
      this.autoLogin(autoLoginGuid);
      return;
    }

    let reason: number;
    this.route.queryParams.subscribe((params) => {
      reason = params['r'];
      if (reason == 1) {
        this.loginInfo = 'Your session has expired or you are not authorised to complete the last action. Please sign in again';
      }
    });
  }

  private autoLogin(autoLoginGuid: string) {
      this.auth
        .loginWithGuid(autoLoginGuid)
        .then((response: AuthResponse) => {
          this.handleLoginResponse(response);
        })
        .catch((error) => {
          this.blockUI.stop();
          this.isError = true;
          this.setErrorMessage('An unknown error has occurred.');
        });
  }

  get username(): AbstractControl {
    return this.dataForm.controls['username'];
  }
  get password(): AbstractControl {
    return this.dataForm.controls['password'];
  }

  onSubmit(): void {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      this.blockUI.start();
      this.auth
        .login(this.dataForm.controls['username'].value, this.dataForm.controls['password'].value)
        .then((response: AuthResponse) => {
          this.handleLoginResponse(response);
        })
        .catch((error) => {
          this.blockUI.stop();
          this.isError = true;
          this.setErrorMessage('An unknown error has occurred.');
        });
    }
  }

  private handleLoginResponse(response: AuthResponse) {
    this.blockUI.stop();

    if (response.isError) {
      this.handleLoginError(response);
    } else {
      this.reset();
      this.handleLoginSuccess(response);
    }
  }

  private handleLoginError(response: AuthResponse) {
    this.isError = response.isError;
    this.setErrorMessage(response.errorMessage);
  }

  private setErrorMessage(errorMessage: string) {
    this.serverErrorMessage = errorMessage;
  }

  private handleLoginSuccess(response: AuthResponse) {
    this.auth.loggedInUserId = +response.data['userId'];
    this.auth.loggedInUserName = response.data['userName'];
    this.auth.loggedInFullName = response.data['fullName'];

    const redirectUrl = this.auth.getRedirectUrl();
    this.router.navigate([redirectUrl]);

    this.cookieService.setLoggedInUserId(this.auth.loggedInUserId);
    this.cookieService.setLoggedInUserName(this.auth.loggedInUserName);
    this.cookieService.setLoggedInFullName(this.auth.loggedInFullName);
  }

  private reset() {
    this.isError = false;
    this.serverErrorMessage = '';
  }
}
