import { Injectable, NgZone } from '@angular/core';
import { ApiAuthService } from 'app/core/security/api/api-auth.service';
import { AuthResponse } from '../api/auth-response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  // store the URL so we can redirect after logging in
  private redirectUrl = '';
  public loggedInUserId = -1;
  public loggedInUserName = '';
  public loggedInFullName = '';
  public loggedInUserIsInternal = '';
  public loggedInUserExtension = '';

  private phoneLoggedIn = new BehaviorSubject<void>(null);
  // Observable itemDeleted stream
  phoneLoggedIn$ = this.phoneLoggedIn.asObservable();

  constructor(
    private apiAuth: ApiAuthService,
  ) {}

  isLoggedIn(): boolean {
    return this.apiAuth.isAuthenticated();
  }

  login(username: string, password: string): Promise<AuthResponse> {
    return this.apiAuth.authenticate(username, password).then(response => {
      return response;
    });
  }

  loginWithGuid(guid: string): Promise<AuthResponse> {
    return this.apiAuth.authenticateWithGuid(guid).then(response => {
      return response;
    });
  }

  logout(): Promise<boolean> {
    return this.apiAuth.deauthenticate();
  }

  setRedirectUrl(url: string): string {
    this.redirectUrl = url;
    return this.redirectUrl;
  }

  getRedirectUrl(): string {
    return this.redirectUrl.length > 0 ? this.redirectUrl : '/';
  }

  getAccessToken(): string {
    return this.apiAuth.getAccessToken();
  }
}
