import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { Division } from 'app/shared/enumeration/division';
import { NotesFormModel } from '../models/notes-form.model';
import { NotesListItemViewModel } from '../models/notes-list-item-view.model';
import { NotesListItemModel } from '../models/notes-list-item.model';

@Injectable()
export class NotesListService extends ListServiceBase {
  divisionId = Division.Supportis;
  taskParentId: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

  }


  //#region Get Endpoints

  get baseEndpoint(): string {
    return '/work-tasks/' + this.taskParentId + '/notes';
  }
  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/task-notes-list';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new NotesListItemViewModel(dataModel as NotesListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: NotesListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: NotesFormModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  async createActivityNote(dataToUpdate: any): Promise<number> {
    let result: number;
    const endpoint = '/divisions/' + this.divisionId + '/activities/notes';
    await this.saveItemNoReload(0, dataToUpdate, endpoint).then((newRecordId) => {
      result = newRecordId;
    });
    return Promise.resolve(result);
  }
}
