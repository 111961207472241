export class RegexValidationStrings {
    public static NUMERIC = '^[0-9.]+$';
    public static INTEGER = '^[0-9]+$';
    public static DECIMAL = '^([0-9]+(\\.)?([0-9]+)?)$';
    public static HALF = '^([0-9]\d*)+(?:\.[05]{1})?$';
    // added extra backslash before . to 'escape' decimal point as 
    // any character was being accepted  
    public static DECIMAL_ONE = '^[0-9]+(\\.[0-9]{1})?$';
    public static DECIMAL_TWO = '^[0-9]+(\\.[0-9]{1,2})?$';
    public static DECIMAL_FOUR = '^[0-9]+(\\.[0-9]{1,4})?$';
    public static ALPHA = '^[a-zA-Z ]+$';
    public static ALPHANUMERIC = '^[a-zA-Z0-9- ]+$';
    public static EMAIL = '^\\s*([a-z\\.A-Z0-9._\\-]+[@][a-zA-Z0-9_\\-]{1,}\\.[a-zA-Z\\.0-9_\\-]{2,})\\s*$';
    public static POSTCODE = '^\\s*([A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]{0,1})\\s*([0-9][A-Za-z]{2})\\s*';
    public static PHONE_NUMBER = '^\\s*((\\(?[0][0-9]{2,5}\\)?)|(\\s*[0][0-9 ]{2,5}\\s*))[0-9 ]{4,10}\\s*$'
    public static PERSON_NAME = '^[a-zA-Z-\' ]+$';
    public static COMPANY_NAME = '^[A-Za-z0-9()./&,\' -]*$';
    public static PASSWORD = '^[a-zA-Z0-9-_\\.,/!£%^&*()?\\\\s]{7,50}$';
    public static WEBSITE_URL = '^((https?|ftp):\\/\\/)?[A-Za-z0-9-]*(\\.[A-Za-z0-9-]*)+(\\/[A-Za-z0-9-\\?&;~=\\$_\\+\\*\\(\\)\'#]*)*$';
    public static NI_NUMBER = '^[a-ceghj-pr-tw-zA-CEGHJ-PR-TW-Z]{1}[a-ceghj-pr-tw-zA-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[a-dfmA-DFM]{0,1}$';
    public static TIME_VALUES = "^(?:[01][0-9]|2[0-3]):[0-5][0-9]$";
}