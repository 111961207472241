import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { LookupList, ToggleList, ToggleTreeItem } from 'app/shared/models/lookup-list.model';

@Injectable()
export class ReactiveFormHelperService {
  constructor() {}

  public markFormControlTouched(group) {
    if (!(group instanceof FormArray) && !(group instanceof FormGroup)) {
      return;
    }

    const vals = Object.keys(group.controls).map((x) => group.controls[x]);

    vals.forEach((control) => {
      if (control && !(control instanceof FormArray)) {
        control.markAsTouched();
      }
      if (control.controls) {
        control.controls.forEach((c) => this.markFormControlTouched(c));
      }
    });
  }

  public isFormValid(dataForm: FormGroup, touchAllControls: boolean = true) {
    if (dataForm == null) {
      return false;
    }

    if (touchAllControls) {
      this.markFormControlTouched(dataForm);
    }

    return dataForm.valid;
  }

  public isFormDirty(dataForm: FormGroup) {
    if (dataForm == null) {
      return false;
    }
    return dataForm.dirty;
  }

  createLookupData(list: LookupList): any[] {
    return list.items.map((item) => {
      return {
        id: +item.id,
        text: item.name,
        // sublists: item.subLists.map(sublist=>{return this.createLookupData(sublist)})
      };
    });
  }

  createLookupDataWithDataObject(list: LookupList): any[] {
    return list.items.map((item) => {
      return {
        id: +item.id,
        text: item.name,
        data: item.data,
        // sublists: item.subLists.map(sublist=>{return this.createLookupData(sublist)})
      };
    });
  }

  createLookupDataFromToggleList(list: ToggleList): any[] {
    return list.items.map((item) => {
      return {
        id: +item.id,
        text: item.name,
        // sublists: item.subLists.map(sublist=>{return this.createLookupData(sublist)})
      };
    });
  }

  createLookupDataFromToggleTreeList(list: ToggleTreeItem[]): any[] {
    if (list == null) {
      return [];
    }

    return list.map((item) => {
      return {
        id: +item.id,
        text: item.name,
        children: this.createLookupDataFromToggleTreeList(item.children),
      };
    });
  }
}
