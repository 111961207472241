import { AfterViewChecked, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivityListService } from '../services/activity-list.service';


@Component({
  selector: 'activity-file-preview',
  templateUrl: './activity-file-preview.component.html',
  styleUrls: ['./activity-file-preview.component.scss']
})
export class ActivityFilePreviewComponent implements AfterViewChecked {
  /** Unique OneDrive identifier. Email attachment IDs are also supported, which should be prefixed with "email-attachment-" */
  @Input() uniqueIdentifier: string;

  /** Set to true to preview email attachments as opposed to OneDrive files. */
  @Input() uniqueIdentifierIsEmailAttachment = false;
  
  /** Sets whether the preview should be displayed or not. Can be bound to a property in the outer component.
   * There may be a better way of dealing with this, but I couldn't find one and this method seems to work well.
   */
  @Input() visible: boolean;

  /** Flags whether we have initiated the background fetch to obtain the externalUrl */
  private loadingStarted = false;
  
  /** Flags whether the externalUrl has been obtained */
  externalUrlSet = false;
  
  /** The URL of the inline preview after being DOM sanitized */
  externalUrlSafe: SafeUrl;

  /** The raw URL of the inline preview returned from MS Graph */
  externalUrlRaw: string;

  constructor(private activityListService:  ActivityListService, private sanitizer: DomSanitizer) {
    // super();
  }

  private get uniqueIdentifierStringToPass(): string {
      if (this.uniqueIdentifierIsEmailAttachment) {
        return 'email-attachment-' + this.uniqueIdentifier
      } else {
        return this.uniqueIdentifier;
      }
  }

  ngAfterViewChecked() {
    if (this.visible) {
      // we only want to load the preview if we are actually visible
      this.ensureLoaded();
    }
  }

  /** Loads the preview if it has not already been loaded. */
  private ensureLoaded() {
    if (!this.loadingStarted) {
      this.loadingStarted = true;
      console.debug('Began loading preview for first time - FS identifier: ' + this.uniqueIdentifierStringToPass);
      this.fetchPreview();
    }
    // else console.debug("Already loaded");
  }

  private fetchPreview() {
    this.activityListService.getInlinePreviewUrls(this.uniqueIdentifierStringToPass).then((urls) => {
      // Sanitize URL and allow view to populate preview with the details
      this.externalUrlRaw = urls.getUrl;
      console.debug('Got preview URL: ' + this.externalUrlRaw);
      this.externalUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.externalUrlRaw);
      this.externalUrlSet = true;
    });

    
  }

}
