import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { UserResetPasswordComponent } from 'app/layout/components/user-reset-password/user-reset-password.component';
import { HeaderComponent } from 'app/layout/header/header.component';
import { LayoutComponent } from 'app/layout/layout-component/layout.component';
import { LayoutRoutesModule } from 'app/layout/layout-routes/layout-routes.module';
import { TreeMenuComponent } from 'app/layout/tree-menu/tree-menu.component';
import { ActivitiesModule } from 'app/modules/activity/activities.module';
import { DatabaseAuditDialog } from 'app/modules/configuration/database-audit/database-audit-dialog/database-audit-dialog.component';
import { DatabaseAuditListFilterService } from 'app/modules/configuration/database-audit/services/database-audit-list.filter.service';
import { DatabaseAuditListService } from 'app/modules/configuration/database-audit/services/database-audit-list.service';
import { TimeProviderCreateUpdateComponent } from 'app/modules/configuration/time-provider/time-provider-create-update/time-provider-create-update.component';
import { TimeService } from 'app/modules/configuration/time/time.service';
import { UserCreateUpdateComponent } from 'app/modules/configuration/user-list-page/user-create-update/user-create-update.component';
// import { UserResetPasswordComponent } from 'app/modules/configuration/user-list-page/user-reset-password/user-reset-password.component';
import { UserListContainerService } from 'app/modules/configuration/user-list-page/user-list-container/user-list-container.service';
import { CompanyContactFormComponent } from 'app/modules/contacts/company-contact-form/company-contact-form.component';
import { CompanyContactListService } from 'app/modules/contacts/services/company-contact-list.service';
import { BlockUIModule } from 'ng-block-ui';
import { SharedModule } from '../shared/shared.module';
import { AsideContainerComponent } from './components/aside-container/aside-container.component';
import { DefaultAsideComponent } from './components/aside-container/default-aside.component';
import { DashboardCardLinkListComponent } from './components/dashboard/dashboard-card-link-list/dashboard-card-link-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AsideContentDirective } from './directives/aside-content.directive';
import { ProfileService } from './profile/services/profile.service';
import { DivisionThemeService } from './services/division-theme.service';
import { MainMenuService } from './services/main-menu.service';
import { ResponsiveService } from './services/responsive.service';
import { SideMenuComponent } from './side-menu/side-menu.component/side-menu.component';
import { DashboardManagementInformationComponent } from './components/dashboard/dashboard-management-information/dashboard-management-information.component';
import { DashboardService } from './services/dashboard.service';
import { TierOneDashboardComponent } from './components/dashboard/dashboard-management-information/tier-one-dashboard/tier-one-dashboard.component';
import { TierTwoDashboardComponent } from './components/dashboard/dashboard-management-information/tier-two-dashboard/tier-two-dashboard.component';
import { TierThreeDashboardComponent } from './components/dashboard/dashboard-management-information/tier-three-dashboard/tier-three-dashboard.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    TreeMenuComponent,
    SideMenuComponent,
    DefaultAsideComponent,
    AsideContainerComponent,
    AsideContentDirective,
    UserCreateUpdateComponent,
    UserResetPasswordComponent,
    TimeProviderCreateUpdateComponent,
    DatabaseAuditDialog,
    WelcomeComponent,
    DashboardComponent,
    DashboardCardLinkListComponent,
    DashboardManagementInformationComponent,
    CompanyContactFormComponent,
    TierOneDashboardComponent,
    TierTwoDashboardComponent,
    TierThreeDashboardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    SharedModule,
    AppMaterialModule,
    ActivitiesModule,
    LayoutRoutesModule // this must be last
  ],
  entryComponents: [
    UserCreateUpdateComponent,
    UserResetPasswordComponent,
    DefaultAsideComponent,
    TimeProviderCreateUpdateComponent,
    DatabaseAuditDialog,
    CompanyContactFormComponent,
  ],
  providers: [
    TimeService,
    ResponsiveService,
    MainMenuService,
    ProfileService,
    UserListContainerService,
    AppMaterialModule,
    DatabaseAuditListService,
    DatabaseAuditListFilterService,
    DivisionThemeService,
    CompanyContactListService,
    DashboardService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule {}
