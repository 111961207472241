import { Injectable } from '@angular/core';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { Division } from 'app/shared/enumeration/division';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { DataService } from 'app/shared/services/data.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivityDocumentUploadModel } from '../models/activity-document-upload.model';

@Injectable()
export class ActivityDocumentUploadService extends DataService<any> {
  @BlockUI() blockUI: NgBlockUI;
  levelParentId: number;
  divisionId = Division.Supportis;

  constructor(private apiDependencies: ApiDependenciesService) {
    super(apiDependencies);
  }

  get baseEndpoint(): string {
    return `/divisions/${this.divisionId}/activities/associated-documents`;
  }

  public baseEndpointWithId(itemId: number): string {
    return `${this.baseEndpoint}/${itemId}`;
  }

  public getUploadEndpoint(): string {
    return `${this.baseEndpoint}/upload`;
  }

  public getUpdateWithUploadEndpoint(itemId: number): string {
    return `${this.baseEndpointWithId(itemId)}/upload`;
  }

  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : '/activities/associated-documents/blank';
  }

  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: ActivityDocumentUploadModel = response;
    return model;
  }

  async getEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;
    await this.setEndpoint(this.getGetEditableItemEndpoint(itemId))
      .getSingle()
      .then((response) => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  async updateWithNoUpload(itemId: number, dataToUpdate: any, endpoint: string = null): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.baseEndpointWithId(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then((response) => {
        result = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }
}
