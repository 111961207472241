import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { UserListContainerService } from 'app/modules/configuration/user-list-page/user-list-container/user-list-container.service';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { UserDetailModel } from 'app/modules/configuration/user-list-page/user-detail.model';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { passwordsMustMatch } from 'app/shared/validation/custom-validators';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'user-edit',
  templateUrl: './user-create-update.component.html'
})
export class UserCreateUpdateComponent implements OnInit {

  public data: any;
  saveText: string;
  editMode: boolean;

  viewModel: UserDetailModel;

  dataForm: FormGroup;

  // shortcuts for validation
  firstNames: AbstractControl;
  lastName: AbstractControl;
  username: AbstractControl;
  password: AbstractControl;
  confirmPassword: AbstractControl;
  roleArray: FormArray;

  constructor(private fb: FormBuilder, private service: UserListContainerService, private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService, private dialog: MatDialog) {

    this.dataForm = fb.group({
      'firstNames': ['', Validators.required],
      'lastName': ['', Validators.required],
      'username': ['', Validators.required],
      'password': [''],
      'confirmPassword': [''],
      'roles': [fb.array([])],
    }, {
        validator: Validators.compose([this.atLeastOneRoleRequired()])
      });

    this.firstNames = this.dataForm.controls['firstNames'];
    this.lastName = this.dataForm.controls['lastName'];
    this.username = this.dataForm.controls['username'];
    this.password = this.dataForm.controls['password'];
    this.confirmPassword = this.dataForm.controls['confirmPassword'];
    this.roleArray = this.dataForm.get('roles') as FormArray;
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';

    let id: number = this.editMode ? this.data.itemId : null;

    this.service.getEditableItemDetail(id).then((response: UserDetailModel) => {
      this.viewModel = response;
      this.initialiseForm();
    });

    if (this.editMode) {
      this.dataForm.removeControl('password');
      this.dataForm.removeControl('confirmPassword');
    }
    else {
      this.password.setValidators([Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$')]);
      this.confirmPassword.setValidators([Validators.required, passwordsMustMatch()]);

    }
  }

  get roleControls(): FormArray {
    return this.dataForm.controls['roles'] as FormArray;
  };


  

  initialiseForm() {
    this.dataForm.controls['firstNames'].setValue(this.viewModel.firstNames);
    this.dataForm.controls['lastName'].setValue(this.viewModel.lastName);
    this.dataForm.controls['username'].setValue(this.viewModel.username);
    this.dataForm.controls['roles'] = this.buildRoles();
  }

  buildRoles() {
    const arr = this.viewModel.roles.map(role => {
      return this.fb.control(role.value);
    });
    return this.fb.array(arr);
  }

  getSelectedRoles(): number[] {
    let roleFormArray = this.dataForm.controls['roles'] as FormArray;
    let roleFormArrayValues = roleFormArray.value;
    let selectedIds: number[] = [];

    for (var i = 0, len = roleFormArrayValues.length; i < len; i++) {
      if (roleFormArrayValues[i] == true) {
        selectedIds.push(this.viewModel.roles[i].id);
      }
    }

    return selectedIds;
  }

  private createUpdateModel() {
    return {
      firstnames: this.firstNames.value,
      lastName: this.lastName.value,
      username: this.username.value,
      password: '',
      roles: this.getSelectedRoles(),
    };
  }
  save() {

    // force validity check on the checklist
    this.roleChanged();
    // force validity check on the confirm password in case 
    this.confirmPassword.updateValueAndValidity();

    if (this.formHelperService.isFormValid(this.dataForm)) {
      let updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateItem(this.data.itemId, updateModel).then(() => {
          this.asideService.close();
        });
      }
      else {
        updateModel.password = this.password.value;

        this.service.addNew(updateModel).then(() => {
          this.asideService.close();
        });
      }
    }
  }

  cancel() {
    let isFormDirty = this.formHelperService.isFormDirty(this.dataForm);

    // the data form isn't maked as dirty when the forma array is dirty so need to add additional check    
    if (isFormDirty || this.dataForm.controls['roles'].dirty) {

      let dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width:'450px',
        data:{
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });
      
      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    }
    else {
      this.asideService.close('cancel');

    }
  }

  // validator can stay on the component if it is specific to just this one
  atLeastOneRoleRequired(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {

      if (group.controls['roles'].value.length > 0 && group.controls['roles'].value.indexOf(true) == -1) {
        return { 'noRoleSelected': { value: true } }
      }
      else {
        return null;
      }
    };
  }

  //#endregion

  roleChanged() {
    this.dataForm.updateValueAndValidity();
  }
}
