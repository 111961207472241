import { CollectionResponse } from 'app/shared/models/collectionResponse.model';
import { ListRequestModel } from 'app/api/models/list-request.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { User } from 'app/modules/user-management/models/user.model';
import { DataService } from 'app/shared/services/data.service';
import { Injectable, InjectionToken } from '@angular/core';
import { IUsersService } from './users.service.interface';
import { UserPermissions, UserPermissionsAction } from '../models/userpermissions.model';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { UserTier } from 'app/shared/enumeration/user-tier';

@Injectable()
export class UsersService extends DataService<User> implements IUsersService {
  public static InjectionToken = new InjectionToken<IUsersService>('usersService');
  private subject = new Subject<UserPermissionsAction>();

  public userPermissions = new UserPermissions();
  public tier: UserTier;
  public showResourcesBtn;

  /** Provides a way for clients to be notified of progress */
  public getMessage(): Observable<UserPermissionsAction> {
    return this.subject.asObservable();
  }

  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
    this.endpoint = '/user-Management/users';
  }

  public async toggleUserActive(userId: number) {
    return this.setEndpoint('/user-Management/change-user-status/' + userId).update();
  }

  public async createNewUser(formData: any) {
    return this.setEndpoint('/user-Management/user').save(formData);
  }

  public async updateExistingUser(userId: number, formData: any) {
    formData.id = userId;
    return this.setEndpoint('/user-Management/user').update(formData);
  }

  public async getUsersList(listRequest: ListRequestModel = null): Promise<User[]> {
    let result: User[] = [];

    await this.queryCollection(listRequest).then((queryResults: CollectionResponse<User>) => {
      result = this.mapUserObjects(queryResults);
    });
    return Promise.resolve(result);
  }

 

public async getUserTier(): Promise<UserTier> {
  let model: UserTier;

  await this.setEndpoint('/current-user-tier')
    .getCollection()
    .then((response) => {
      model = <UserTier>response;
    });

  return Promise.resolve(model);
}

public async getUserProductTypes(): Promise<UserTier> {
  let model: UserTier;

  await this.setEndpoint('/current-user-product-types')
    .getCollection()
    .then((response) => {
      model = response;
    });

  return Promise.resolve(model);
}

  /* Note:   As this is a singleton service, userPermissions will only be fetched once per login.
       Future: Get permissions from Web API? WebaPI should also have a role based permissions attibute rather than role based auth attributes */
  public async ensurePermissions(): Promise<UserPermissions> {
    const userName = this.dependencies.cookieService.getLoggedInUserName();

    
    if (userName === '') {
      if (this.userPermissions.isInitialised) {
        this.userPermissions = new UserPermissions();
      }

      // No permissions
      this.subject.next(new UserPermissionsAction('Current user has no permissions'));
      return Promise.resolve(this.userPermissions);
    }

    if (this.userPermissions.isInitialised) {
      if (userName === this.userPermissions.userName) {
        return Promise.resolve(this.userPermissions);
      }

      // Application has not changed but different user now logged in
      this.userPermissions = new UserPermissions();
    }
    this.tier = await this.getUserTier();
    this.showResourcesBtn = await this.getUserProductTypes();
    
    this.userPermissions.tierOne = this.tier === UserTier.TierOne;
    this.userPermissions.tierTwo = this.tier === UserTier.TierOne || this.tier === UserTier.TierTwo;
    this.userPermissions.tierThree = this.tier === UserTier.TierOne || this.tier === UserTier.TierTwo || this.tier === UserTier.TierThree;

    this.userPermissions.action.configuration.isEnabled = this.userPermissions.action.configuration.getIsEnabled();

    this.userPermissions.markAsInitialised(userName);

    this.userPermissions.action.menu.available = true;

    this.subject.next(new UserPermissionsAction('Permissions are available'));
    return Promise.resolve(this.userPermissions);
  }

  public async hasPermission(permission: string): Promise<boolean> {
    if (!permission) {
      return Promise.resolve(false);
    }

    await this.ensurePermissions();
    const val = this.recompose(this.userPermissions, permission);
    return Promise.resolve(val);
  }

  

  private recompose(obj: any, s: string) {
    const parts = s.split('.');
    const newObj = obj[parts[0]];

    if (parts[1]) {
      parts.splice(0, 1);
      const newString = parts.join('.');
      return this.recompose(newObj, newString);
    }

    return newObj;
  }

  private mapUserObjects(queryResults: CollectionResponse<User>): User[] {
    const result: User[] = [];

    queryResults.items.forEach((user) => {
      result.push(new User(user.id, user.username, user.firstName, user.surname, user.email, user.mobileNumber, user.isActive, user.roles));
    });

    return result;
  }
}
