import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'no-results',
  template: `
    <div class="table-no-results">
      {{message}}
    </div>
  `
})
export class NoResultsComponent implements OnInit {

    @Input() message = 'No Results'
    constructor() { }
  
    ngOnInit() {
    }  
}
