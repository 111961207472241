exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".nav-container {\n  margin-top: 64px; }\n\n.mat-drawer-container {\n  z-index: -1; }\n\n.content {\n  width: 100%; }\n\n.content-menu {\n  float: left; }\n\n.content-main {\n  float: left;\n  width: 100%;\n  padding-left: 15px;\n  padding-right: 15px; }\n\n.logo img {\n  display: block;\n  margin-left: auto;\n  margin-right: auto; }\n\n.side-menu {\n  width: 250px;\n  border-right: solid 2px #29379d; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();