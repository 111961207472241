exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".welcome-card {\n  width: 600px;\n  text-align: center;\n  margin: auto;\n  margin-top: 50px; }\n\nh1 {\n  font-size: 1.5rem;\n  text-align: center; }\n\n.welcome-logo {\n  max-height: 110px;\n  width: auto;\n  height: auto;\n  padding-top: 15px; }\n\n.center {\n  text-align: center; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();