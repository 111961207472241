import { Injectable } from '@angular/core';
import { LookupList, LookupItem } from 'app/shared/models/lookup-list.model';
import { DataService } from 'app/shared/services/data.service';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';

@Injectable()
export class DatabaseAuditListFilterService extends DataService<any> {

  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  async getEventTypeLookups(): Promise<LookupList> {
    let model: LookupList;
    await this.setEndpoint('/audit/event-type-list-lookup')
      .getSingle()
      .then(response => {
        model = response.eventTypeList;

        model.items.unshift(new LookupItem(null, '', null));
      });

    return Promise.resolve(model);
  }
}
