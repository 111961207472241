import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { DatabaseAuditListItemViewModel } from '../models/database-audit-list-item-view.model';
import { DatabaseAuditListItemModel } from '../models/database-audit-list-item.model';

@Injectable()
export class DatabaseAuditListService extends ListServiceBase {
  
  constructor(protected dependencies: ApiDependenciesService, protected dialog : MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint():string{
    return "/audit";
  }
  baseEndpointWithId(id: number):string{
    return this.baseEndpoint + "/" + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations


  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + "/list";
  }
  
  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor 
  createListViewModel(dataModel: ApiEntity) {
    return new DatabaseAuditListItemViewModel(dataModel as DatabaseAuditListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    let model:DatabaseAuditListItemModel = responseItem;
    return model;
  }







//end of class
}
