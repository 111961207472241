import { ApiEntity } from 'app/api/models/api-entity.model';

export class NotesFormModel extends ApiEntity {
  constructor(id: number = null, public content: string = '', public allowAttachmentUpload: boolean = false) {
    super(id);
  }
}

export class CaseNoteFormModel extends ApiEntity {
  constructor(
    id: number = null,
    public description: string = '',
    public content: string = '',
    public timestamp: string = '',
    public activityLevel: number = null,
    public levelParentId: number = null
  ) {
    super(id);
  }
}
