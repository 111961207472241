import { ExtendedBlob } from 'app/shared/models/extended-blob';

export function downloadFileFromExtendedBlob(extendedBlob: ExtendedBlob): void {

    if (window.navigator.msSaveOrOpenBlob) {  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(extendedBlob.blob, extendedBlob.metaData);
    } else {
        const objectUrl = window.URL.createObjectURL(extendedBlob.blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = extendedBlob.metaData;
        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
}
