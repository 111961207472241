exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":host {\n  display: block; }\n\n.container {\n  background-color: #292929;\n  padding: 0.5rem 1rem;\n  border-radius: 8px; }\n\n.content {\n  color: white; }\n\n.header {\n  color: aqua;\n  font-size: 20px;\n  border-bottom: solid 1px #666; }\n\n.footer {\n  color: chartreuse;\n  font-size: 10px;\n  border-top: solid 1px #666; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();