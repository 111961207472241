exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fill-remaining-space {\n  flex: auto; }\n\nmat-toolbar a {\n  font-size: 1rem; }\n\n.main-menu {\n  margin-left: 50px; }\n\n.breadcrumb {\n  font-size: 8px; }\n\n.header-title {\n  font-size: 24px;\n  padding-left: 14px; }\n\n.header-logo-container {\n  padding-left: 30px;\n  padding-top: 8px; }\n\n.header-logo {\n  display: block;\n  max-height: 40px;\n  width: auto;\n  height: auto; }\n\n.primaryBackgroundColor {\n  height: 10px;\n  background: #dc385f !important; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();