import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutesModule } from 'app/core/app-routes/app-routes.module';
import { AppComponent } from 'app/core/app-component/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { LoginComponent } from 'app/core/security/login/login.component';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { AppAuthGuardService } from 'app/core/security/guards/app-auth-guard.service';
import { AuthService } from 'app/core/security/auth/auth.service';
import { ApiAuthService } from 'app/core/security/api/api-auth.service';
import { SharedModule } from './shared/shared.module';
import { ApiDependenciesService } from './api/services/api-dependencies.service';
import { LayoutModule } from 'app/layout/layout.module';
import { FilterFieldsBaseComponent } from 'app/shared/components/list-filter/filter-fields-base.component';
import { AsideService } from './layout/components/aside-container/aside.service';
import { UserPermissionGuardService } from 'app/core/security/guards/user-permission-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UsersService } from './modules/user-management/services/users.service';
import { ActivateUserService } from './modules/customer/activate-user/activate-user.service';
import { EditorModule } from '@tinymce/tinymce-angular';

export const KALEIDA_UK_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent, LoginComponent, FilterFieldsBaseComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppMaterialModule,
    BlockUIModule.forRoot(),
    SharedModule,
    // import 3rd party plugin imports after Angular imports
    LayoutModule,
    EditorModule,
    AppRoutesModule, // This needs to be last.
  ],
  providers: [
    AppAuthGuardService,
    AuthService,
    ApiAuthService,
    UserPermissionGuardService,
    ApiDependenciesService,
    UsersService,
    AsideService,
    ActivateUserService,

    // Use moment date adapter rather than native
    // https://stackblitz.com/angular/mrjaokybbxl?file=app%2Fdatepicker-locale-example.ts
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: KALEIDA_UK_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
