exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "ul,\nli {\n  list-style-type: none;\n  margin: 0; }\n\n.mat-tree-node {\n  background-color: #f5f5f5;\n  margin-left: 20px;\n  min-height: 2.0em; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();