import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ChangeElement {
  name: string;
  before: any;
  after: any;
}

@Component({
  selector: 'database-audit-dialog',
  templateUrl: 'database-audit-dialog.component.html',
  styleUrls: ['database-audit-dialog.component.scss']
})
export class DatabaseAuditDialog {
  displayedColumns: string[] = ['name', 'before', 'after'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: ChangeElement[]) {}
}
