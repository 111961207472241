import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { environment } from 'environments/environment';

//TODO refactor to use local storage?
//Cookies are sent with every request, more secure to only send in the way intended, i.e as bearer token
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AuthResponse } from './auth-response.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

//TODO rename this service to be password credential flow specific
//TODO implmenet interface with auth and deauth methods for app auth service to consume
@Injectable()
export class ApiAuthService {
  //private clientId: number = 1; //TODO configuration file setting
  //private clientSecret: string = 'aaaaaaaa-dfb2-4cf8-8151-d85b41cd7be1'; //TODO configuration file setting
  //private baseApiUrl: string = 'http://localhost/Kaleida.drivefurther.WebService'; //TODO configuration file setting

  protected clientSecret: string = environment.clientSecret;
  protected readonly apiPrefix: string = environment.apiPrefix;
  protected readonly baseApiUrl: string = environment.baseUrl;
  protected apiUrl: string = this.baseApiUrl + this.apiPrefix;

  constructor(private http: HttpClient) {}

  accessToken = '';
  refreshToken = '';

  public async authenticate(username: string, password: string): Promise<AuthResponse> {
    const result: AuthResponse = new AuthResponse();

    await this.requestNewAccessToken(username, password, null)
      .then((res) => {
        this.processRequestNewAccessTokenResponse(result, res);
      })
      .catch((error) => {
        this.handleRequestNewAccessTokenResponseError(result, error);
      });

    return Promise.resolve(result);
  }

  public async authenticateWithGuid(guid: string): Promise<AuthResponse> {
    const result: AuthResponse = new AuthResponse();

    await this.requestNewAccessToken('', '', guid)
      .then((res) => {
        this.processRequestNewAccessTokenResponse(result, res);
      })
      .catch((error) => {
        console.log(error);
        this.handleRequestNewAccessTokenResponseError(result, error);
      });

    return Promise.resolve(result);
  }

  private handleRequestNewAccessTokenResponseError(result: AuthResponse, error: any) {
    result.isError = true;

    // TODO If response is not JSON, return some error general information manually based on http status code
    if (error.status === 404) {
      result.errorType = 'Not Found';
      result.errorMessage = 'HTTP Error 404. The requested resource is not found.';
    } else if (error.status === 400) {
      result.errorType = 'Bad Request';
      result.errorMessage = error.error.error_description;
    } else {
      const body = JSON.parse(error._body);

      result.errorType = body.error;
      result.errorMessage = body.error_description;
    }
  }

  private processRequestNewAccessTokenResponse(result: AuthResponse, res: Object) {
    result.data = res;
    this.accessToken = result.data.access_token;
    this.refreshToken = result.data.refresh_token;
    Cookie.set('dfportal_accessToken', this.accessToken, null, '/');
  }

  public deauthenticate(): Promise<boolean> {
    Cookie.delete('dfportal_accessToken');
    // Cookie.deleteAll();

    this.accessToken = '';
    this.refreshToken = '';
    return Promise.resolve(true);
  }

  public isAuthenticated(): boolean {
    if (this.accessToken.length > 0) {
      return true;
    }
    return this.checkCookie();
  }

  private checkCookie(): boolean {
    const accessTokenFromCookie = Cookie.get('dfportal_accessToken');

    if (accessTokenFromCookie !== null && accessTokenFromCookie !== undefined && accessTokenFromCookie.length > 0) {
      this.accessToken = accessTokenFromCookie;
      return true;
    }
    return false;
  }

  private requestNewAccessToken(username: string, password: string, guid: string): Promise<Object> {
    const url = this.apiUrl + '/token';
    let body;
    if (guid) {
      body = new HttpParams().set('grant_type', 'password').set('portal_login_guid', guid).set('client_secret', this.clientSecret);
    } else {
      body = new HttpParams().set('grant_type', 'password').set('client_secret', this.clientSecret).set('username', username).set('password', password);
    }

    return this.http.post(url, body).toPromise(); //async&await only works with promises
  }

  getAccessToken(): string {
    const accessTokenFromCookie = Cookie.get('dfportal_accessToken');
    return accessTokenFromCookie;
  }
}
