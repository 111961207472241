import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot, CanActivate } from '@angular/router';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { AuthService } from 'app/core/security/auth/auth.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { CookieService } from 'app/shared/services/cookie.service';


@Injectable()
export class UserPermissionGuardService implements CanActivate {
  constructor(
    protected usersService: UsersService,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected cookieService: CookieService,
    protected dependencies: ApiDependenciesService
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let canActivate = false;
    let redirect = 'dashboard';
    if (route.data.redirect) {
      redirect = route.data.redirect;
    }
  
    if (route.data.permission) {
      canActivate = await this.usersService.hasPermission(route.data.permission);
      if(!canActivate) {
        this.dependencies.snackBar.warning('You do not have permission to view this');
      }
    } 

    if (!canActivate && redirect !== '') {
      this.router.navigate([redirect]);
    }

    return Promise.resolve(canActivate);
  }

  
}
