import { Injectable, ViewContainerRef } from "@angular/core";
import { ApiDependenciesService } from "app/api/services/api-dependencies.service";
import { UserTier } from "app/shared/enumeration/user-tier";
import { DataService } from "app/shared/services/data.service";

@Injectable()
export class DashboardService extends DataService<any> {
  
  
    constructor(dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  getDashboardManagementInformationDataEndpoint(): string {
    return `/portal/dashboard-management-information`;
  }

  getTierOneDashboardEndpoint(): string {
    return `${this.getDashboardManagementInformationDataEndpoint()}/tier-one`;
  }

  getTierTwoDashboardEndpoint(): string {
    return `${this.getDashboardManagementInformationDataEndpoint()}/tier-two`;
  }

  getTierThreeDashboardEndpoint(): string {
    return `${this.getDashboardManagementInformationDataEndpoint()}/tier-three`;
  }

  async getDashboardManagementInformationData(endpoint: string): Promise<any> {
    // this.blockUI.start();  
    let model: any;

    await this
      .setEndpoint(endpoint)
      .getSingle().then((response: any) => {
        model = response;
        // this.blockUI.stop();
      })
      .catch(() => {
        // this.blockUI.stop();
      });

    return Promise.resolve(model);
  }


}
