import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { downloadFileFromExtendedBlob } from 'app/shared/helpers/file-download-helpers';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { ActivityListItemViewModel } from '../models/activity-list-item-view.model';
import { ActivityListItemModel } from '../models/activity-list-item.model';
import { CaseActivityModel } from '../models/case-activity.model';
import { CaseActivityListMode } from '../models/CaseActivityListMode';

@Injectable()
export class ActivityListService extends ListServiceBase {
  listMode = CaseActivityListMode.History;

  divisionId: number;
  levelParentId: number;
  activityLevel: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog, private snackBar: SnackBarService) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    return '/portal-activities';
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  get baseEndpointForTribunalPack(): string {
    return '/case-claims/' + this.levelParentId + '/tribunal-activities';
  }

  // get baseEndpointForTribunalPack(): string {
  //   return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/tribunal-activities';
  // }

  // get baseActivityEndpoint(): string {
  //   return '/divisions/' + this.divisionId + '/activities/custom-activities';
  // }

  // baseActivityEndpointWithId(id: number): string {
  //   return '/divisions/' + this.divisionId + '/activities/custom-activities/' + id;
  // }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  // protected getCommandStubEndpoint(itemId: number): string {
  //   return this.baseEndpointWithId(itemId);
  // }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {

    if (this.listMode === CaseActivityListMode.TribunalPackContents) {
      return this.baseEndpointForTribunalPack + '/included';
    } 

    switch (this.activityLevel) {
      case ActivityLevel.PortalDashboard:
        return this.baseEndpoint;

      case ActivityLevel.Case:
        return '/case-claims/' + this.levelParentId + '/case-activity-list';

      case ActivityLevel.Product:
        return '/products/' + this.levelParentId + '/product-activity-list';

      case ActivityLevel.Client:
        return '/client-activity-list';
    }
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new ActivityListItemViewModel(dataModel as ActivityListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: ActivityListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseActivityModel = response;
    return model;
  }

  //#endregion

  // protected getCreateEndpoint(): string {
  //   return this.baseEndpoint;
  // }

  // protected getUpdateEndpoint(itemId: number): string {
  //   return this.baseEndpointWithId(itemId);
  // }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async download(itemId: number) {
    await this.sendCommand(itemId, 'download');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async notes(itemId: number) {
    await this.sendCommand(itemId, 'notes');
  }

  // OVERRIDDEN from ListServiceBase
  // protected getGetEditableItemEndpoint(itemId: number) {
  //   return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  // }

  protected get filterLookupDataEndpoint(): string {
    return this.baseEndpoint + '/filter-lookup-data';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async downloadPack() {
    this.blockUI.start();
    await this.setEndpoint(`/case-claims/${this.levelParentId}/download-tribunal-pack`)
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
  }

  async downloadFile(uniqueIdentifier: string) {
    await this.setEndpoint('/file-store/' + uniqueIdentifier + '/download')
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async downloadEmailAttachment(itemId: any) {
    await this.setEndpoint(`/divisions/${this.divisionId}/group-inbox-items/${itemId}/email-attachment`)
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async getInlinePreviewUrls(uniqueIdentifier: string): Promise<OfficeInlinePreviewDetailsModel> {
    let model: OfficeInlinePreviewDetailsModel;
    const endpoint = '/file-store/' + uniqueIdentifier + '/preview-inline-urls';
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: OfficeInlinePreviewDetailsModel) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async createActivityNote(dataToUpdate: any): Promise<number> {
    let result: number;
    const endpoint = '/divisions/' + this.divisionId + '/activities/notes';
    await this.saveItemNoReload(0, dataToUpdate, endpoint).then((newRecordId) => {
      result = newRecordId;
    });
    return Promise.resolve(result);
  }
}
