import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { ActivityListService } from '../services/activity-list.service';

@Component({
  selector: 'activity-document-pack-list',
  templateUrl: './activity-document-pack-list.component.html',
  styleUrls: ['./activity-document-pack-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})

export class ActivityDocumentPackListComponent extends AddListComponent implements OnInit {
  @Input() listData: ActivityDocumentPackListItemModel[];

  itemList: ActivityDocumentPackListItemViewModel[];

  get displayedColumns(): string[] {
    return ['name', 'actions'];
  }

  constructor(private activityListService: ActivityListService) {
    super(activityListService);
    this.loadAfterInit = false;
  }

  ngOnInit() {
    this.itemList = [];
    this.listData.forEach((dataModel: ApiEntity) => {
      const model = new ActivityDocumentPackListItemViewModel(dataModel as ActivityDocumentPackListItemModel);
      model.itemId = dataModel.id;
      this.itemList.push(model);
    });
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        this.activityListService.downloadFile(message.data.dataModel.uniqueIdentifier);
        break;
      }      
    }
  }


  toggleExpanded(row: ActivityDocumentPackListItemViewModel) {
    row.expanded = !row.expanded;
    console.debug('Document pack item toggleExpanded() - set to: ' + row.expanded);
  }

}

export class ActivityDocumentPackListItemModel extends ApiEntity {
  constructor(
    id: number = null,
    parentActivityId: number = null,
    public name: string = '',
    public uniqueIdentifier: string = '',
    public isGeneratedDocument: boolean = false,
    public canEdit: boolean = false,
    public cannotEditReason: string = '',
    public canRegenerate: boolean = false
  ) {
    super(id);
  }
}

export class ActivityDocumentPackListItemViewModel extends ActionableItemState {
  downloadButton: IIconActionButton;
  expanded = false;

  constructor(dataModel: ActivityDocumentPackListItemModel) {
    super();

    this.dataModel = dataModel;

    this.downloadButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'cloud_download',
      actionName: 'download',
      hoverText: 'Download the item',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.downloadButton);
  }
}
