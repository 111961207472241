import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { UserRowModel } from 'app/modules/configuration/user-list-page/user-list-container/user-row.model';

export class UserRowViewModel extends ActionableItemState {
  editButton: IIconActionButton;
  deactivateButton: IIconActionButton;
  resetPasswordButton: IIconActionButton;
  deleteButton: IIconActionButton;

  constructor(dataModel: UserRowModel) {
    super();

    this.dataModel = dataModel;

    this.dataModel = dataModel;
    const disableIcon = dataModel.isActive === true ? 'block' : 'check';
    const disableAction = dataModel.isActive === true ? 'deactivate' : 'activate';

    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'edit',
      actionName: 'edit',
      hoverText: 'edit the user',
      class: '',
      buttonText: ''
    };
    this.deactivateButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: disableIcon,
      actionName: disableAction,
      hoverText: disableAction,
      class: '',
      buttonText: ''
    };
    this.resetPasswordButton = {
      visible: true,
      enabled: dataModel.isActive,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'lock',
      actionName: 'resetpassword',
      hoverText: 'reset the user password',
      class: '',
      buttonText: ''
    };
    this.deleteButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'delete',
      actionName: 'delete',
      hoverText: 'delete the user',
      class: '',
      buttonText: ''
    };

    this.processActions.push(this.editButton);
    this.processActions.push(this.deactivateButton);
    this.processActions.push(this.resetPasswordButton);
    this.processActions.push(this.deleteButton);
  }
}
