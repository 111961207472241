import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { TimeProviderDetailModel } from '../time-provider-detail.model';
import { TimeService } from '../../time/time.service';
// import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

@Component({
  selector: 'time-provider-edit',
  templateUrl: './time-provider-create-update.component.html' //,
  // providers: [
  //   {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},

  //   // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
  //   // `MatMomentDateModule` in your applications root module. We provide it at the component level
  //   // here, due to limitations of our example generation script.
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  //   {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  // ]
})
export class TimeProviderCreateUpdateComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  viewModel: TimeProviderDetailModel;

  dataForm: FormGroup;

  // shortcuts for validation
  // now: AbstractControl;
  // realTime: AbstractControl;
  // fixedTime: AbstractControl;
  // baseDate: AbstractControl;
  // incrementDays: AbstractControl;
  // incrementTime: AbstractControl;

  constructor(
    fb: FormBuilder,
    private service: TimeService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.dataForm = fb.group({
      now: [''],
      realTime: [''],
      fixedTime: [''],
      baseDate: [''],
      incrementDays: [''],
      incrementTime: ['']
    });
  }

  get realTime(): AbstractControl {
    return this.dataForm.controls['realTime'];
  }
  get now(): AbstractControl {
    return this.dataForm.controls['now'];
  }
  get fixedTime(): AbstractControl {
    return this.dataForm.controls['fixedTime'];
  }
  get incrementDays(): AbstractControl {
    return this.dataForm.controls['incrementDays'];
  }
  get incrementTime(): AbstractControl {
    return this.dataForm.controls['incrementTime'];
  }
  get baseDate(): AbstractControl {
    return this.dataForm.controls['baseDate'];
  }

  ngOnInit() {
    this.editMode = true;
    this.saveText = 'Update';

    this.service.getEditableItemDetail().then((response: TimeProviderDetailModel) => {
      this.viewModel = response;
      this.initialiseForm();
    });
  }

  showIncremental = () => {
    return this.dataForm.controls['realTime'] ? !this.realTime.value : false;
  };
  showFixed = () => {
    return this.dataForm.controls['realTime'] ? !this.realTime.value : false;
  };
  currentDateTime = () => {
    return this.dataForm.controls['now'].value;
  };

  initialiseForm() {
    this.dataForm.controls['now'].setValue(this.viewModel.Now);
    this.dataForm.controls['realTime'].setValue(this.viewModel.RealTime);
    this.dataForm.controls['fixedTime'].setValue(this.viewModel.FixedTime);
    this.dataForm.controls['baseDate'].setValue(this.viewModel.BaseDate);
    this.dataForm.controls['incrementDays'].setValue(this.viewModel.IncrementDays);
    this.dataForm.controls['incrementTime'].setValue(this.viewModel.IncrementTime);
  }

  private createUpdateModel() {
    return {
      now: this.now.value,
      realTime: this.realTime.value,
      fixedTime: this.fixedTime.value,
      baseDate: this.baseDate.value,
      incrementDays: this.incrementDays.value,
      incrementTime: this.incrementTime.value
    };
  }
  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      this.service.updateItem(updateModel).then(() => {
        this.asideService.close();
      });
    }
  }

  cancel() {
    const isFormDirty = this.formHelperService.isFormDirty(this.dataForm);

    // the data form isn't maked as dirty when the forma array is dirty so need to add additional check
    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
