import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { KaleidaPopoverComponent } from './popover.component';

// https://blog.angularindepth.com/building-tooltips-for-angular-3cdaac16d138

@Directive({ selector: '[kaleidaPopover]' })
export class KaleidaPopoverDirective implements OnInit {

  @Input('kaleidaPopover') text = '';
  @Input() headerText = 'default header';

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -8,
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    const tooltipRef: ComponentRef<KaleidaPopoverComponent>
      = this.overlayRef.attach(new ComponentPortal(KaleidaPopoverComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.header = this.headerText;
    }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }
}
