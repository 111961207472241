/**
 * Pad the beginning of a string with a given character until it reaches the length provided
 * @param {string} string - The string to pad
 * @param {number} length - The length of the string required after padding
 * @param {string} padWith - What to pad the string with
 * @example padStart('1', 3, '0') // returns '001'
 */
export function padStart(string: string, length: number, padWith: string) {
    const pad = new Array(1 + length).join(padWith);
    return (pad + string).slice(-pad.length);
}

export class StringUtilities {

    public static generateNewGuid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    public static translateCR(source: string) {
        return source.replace(/(?:\r\n|\r|\n)/g, '<br />')
        
    }

}
