import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { KALEIDA_UK_DATE_FORMATS } from 'app/app.module';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { SharedModule } from 'app/shared/shared.module';
import { ActivityDocumentPackListComponent } from './activity-document-pack-list/activity-document-pack-list.component';
import { ActivityDocumentUploadFormComponent } from './activity-document-upload-form/activity-document-upload-form.component';
import { ActivityEmailAttachmentComponent } from './activity-email-attachment-list/activity-email-attachment-list.component';
import { ActivityFilePreviewComponent } from './activity-file-preview/activity-file-preview.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivityNoteFormComponent } from './activity-note-form/activity-note-form.component';
import { ActivityListFilterComponent } from './case-activity-list-filter/activity-list-filter.component';
import { ActivityDocumentUploadService } from './services/activity-document-upload.service';
import { ActivityListService } from './services/activity-list.service';
import { NotesListService } from './services/notes-list.service';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, FormsModule, ReactiveFormsModule, AppMaterialModule],
  declarations: [
    ActivityListComponent,
    ActivityDocumentPackListComponent,
    ActivityEmailAttachmentComponent,
    ActivityFilePreviewComponent,
    ActivityListFilterComponent,
    ActivityNoteFormComponent,
    ActivityDocumentUploadFormComponent,
  ],
  exports: [
    ActivityListComponent,
    ActivityDocumentPackListComponent,
    ActivityEmailAttachmentComponent,
    ActivityFilePreviewComponent,
    ActivityListFilterComponent,
  ],
  entryComponents: [ActivityNoteFormComponent, ActivityDocumentUploadFormComponent],
  providers: [
    ActivityListService,
    NotesListService,
    ActivityDocumentUploadService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: KALEIDA_UK_DATE_FORMATS },
  ],
})
export class ActivitiesModule {}
