import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { CompanyContactListItemModel } from './company-contact-list-item.model';

export class CompanyContactListItemViewModel extends ActionableItemState {
  createUserButton: IIconActionButton;
  editButton: IIconActionButton;
  activateItemButton: IIconActionButton;
  resetPasswordButton: IIconActionButton;

  constructor(dataModel: CompanyContactListItemModel) {
    super();

    this.dataModel = dataModel;
    const disableIcon = dataModel.isActive === true ? 'block' : 'check';
    const disableAction = dataModel.isActive === true ? 'Deactivate' : 'Activate';

    this.createUserButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.canCreateAccount,
      actionDisabledMessage: this.dataModel.cannotCreateAccountReason,
      iconName: 'person',
      actionName: 'createUserItem',
      hoverText: 'Create a user account',
      class: this.dataModel.hasAccount ? 'primary-color' : '',
      buttonText: '',
    };

    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.hasAccount,
      actionDisabledMessage: this.dataModel.disabledReason,
      iconName: 'edit',
      actionName: 'edit',
      hoverText: 'Edit the item',
      class: '',
      buttonText: '',
    };

    this.activateItemButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.hasAccount,
      actionDisabledMessage: this.dataModel.disabledReason,
      iconName: disableIcon,
      actionName: 'activateToggle',
      hoverText: disableAction,
      class: '',
      buttonText: '',
    };

    this.resetPasswordButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.hasAccount,
      actionDisabledMessage: this.dataModel.disabledReason,
      iconName: 'lock',
      actionName: 'resetPassword',
      hoverText: 'Reset Password',
      class: '',
      buttonText: '',
    };

    this.processActions.push(this.createUserButton);
    this.processActions.push(this.editButton);
    this.processActions.push(this.resetPasswordButton);
    this.processActions.push(this.activateItemButton);
  }
}
