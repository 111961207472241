import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { PostcodeLookupResponseModel, PostcodeLookupResponseItemModel } from './models/postcode-lookup.model';
import { PostcodeLookupService } from './services/postcode-lookup.service';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'postcode-lookup',
    templateUrl: './postcode-lookup.component.html'
  })

  export class PostcodeLookupComponent implements OnInit {

    postcodeForm: FormGroup;
    responseModel: PostcodeLookupResponseModel = null;
    lastSearchedPostcode: '';
    addressLookupData: any[];
    @Output() selectedAddressChanged = new EventEmitter<PostcodeLookupResponseItemModel>();

    @ViewChild('addressList') addressListDropdown: MatSelect;

    constructor(private fb: FormBuilder, private service: PostcodeLookupService) {        
    }

    get postcode(): AbstractControl { return this.postcodeForm.controls['postcode']; }
    get selectAddress(): AbstractControl { return this.postcodeForm.controls['selectAddress']; }

    enableLookupButton = () => {
      return this.postcode.value !== '' && this.postcode.value !== undefined && this.postcode.value !== null && this.postcode.value !== this.lastSearchedPostcode;
    }

    ngOnInit() {
      this.postcodeForm = this.fb.group({
        'postcode': [''],
        'selectAddress': [''],      
      });
      
      this.postcodeForm.markAsPristine();
      this.selectAddress.valueChanges.subscribe(value => this.addressSelected(value));
    }

    lookup() {
      this.service.getPostcodeLookupResponse(this.postcode.value).then((response: PostcodeLookupResponseModel) => {
        this.responseModel = response;
        this.addressLookupData = this.createAddressLookupData(response.responseItems);
        this.lastSearchedPostcode = this.postcode.value;
        this.selectAddress.patchValue(null);

        if (response.responseItems.length > 0) {
          this.addressListDropdown.placeholder = `${response.responseItems.length} addresses found. Please Select`
          this.addressListDropdown.toggle();
        } else {
          this.addressListDropdown.placeholder = 'No addresses listed. Please enter a valid postcode'
        }

      });
    }

    createAddressLookupData(list: PostcodeLookupResponseItemModel[]): any[] {
      return list.map(item => {
        return {
          id: list.indexOf(item),
          text: [item.organisation, item.line1, item.line2, item.line3, item.town, item.county, item.postcode].filter(Boolean).join(', ')
        }
      });
    }

    public addressSelected(value): void {
      const data = value !== null ? this.responseModel.responseItems[value] : null;
      this.selectedAddressChanged.emit(data);
    }

  }
