import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'app/core/security/login/login.component';
import { AppAuthGuardService } from 'app/core/security/guards/app-auth-guard.service';
import { PageNotFoundComponent } from 'app/core/app-routes/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:uniqueIdentifier',
    component: LoginComponent
  },
  {
    path: '**',
    canActivate: [AppAuthGuardService],
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  declarations: [
    PageNotFoundComponent
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutesModule { }
