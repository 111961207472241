import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'app/layout/services/dashboard.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'tier-two-dashboard',
  templateUrl: './tier-two-dashboard.component.html',
  styleUrls: ['./tier-two-dashboard.component.scss']
})
export class TierTwoDashboardComponent implements AfterViewInit {

    groupedCallsChart: Chart;
    @ViewChild('groupedCallsCanvas') groupedCallsCanvas: ElementRef<HTMLCanvasElement>;
    public groupedCallsContext: CanvasRenderingContext2D;

    groupedCasesChart: Chart;
    @ViewChild('groupedCasesCanvas') groupedCasesCanvas: ElementRef<HTMLCanvasElement>;
    public groupedCasesContext: CanvasRenderingContext2D;

    numberOfCasesInLast12Months: number = 0;
    numberOfTribunalsInLast12Months: number = 0;
    staticColoursInSizeOrder = [
        '#311D41',
        '#5D377D',
        '#042458',
        '#3A41D6',
        '#DC385F',
        '#E87893',
        '#FFFFFF',
        '#854FB1',
        '#000000',
        '#7F7F7F',
    ];


  constructor(
        private service: DashboardService,
        private router: Router) {
    }


  ngAfterViewInit() {
    this.groupedCallsContext = this.groupedCallsCanvas.nativeElement.getContext('2d');
    this.groupedCasesContext = this.groupedCasesCanvas.nativeElement.getContext('2d');
    this.loadDashboardData();
  }

  loadDashboardData() {
    this.service.getDashboardManagementInformationData(this.service.getTierTwoDashboardEndpoint()).then((response: any) => {
        let groupedCallsChartLabels = response.caseTypeCallCounts.map((item) => {return item.description});
        let groupedCallsChartData = response.caseTypeCallCounts.map((item) => {return item.count});
        let groupedCallsChartBackgroundColours = this.getColourArrayForChartData(response.caseTypeCallCounts.length);

        let groupedCasesChartLabels = response.caseTypeCaseCounts.map((item) => {return item.description});
        let groupedCasesChartData = response.caseTypeCaseCounts.map((item) => {return item.count});
        let groupedCasesChartBackgroundColours = this.getColourArrayForChartData(response.caseTypeCaseCounts.length);

        if (groupedCallsChartData.length > 0) {
            this.initChart(this.groupedCallsChart, this.groupedCallsContext, groupedCallsChartLabels, groupedCallsChartData, groupedCallsChartBackgroundColours, 'Number of Phone Calls over last 12 Months', false);
        }

        if (groupedCasesChartData.length > 0) {
            this.initChart(this.groupedCasesChart, this.groupedCasesContext, groupedCasesChartLabels, groupedCasesChartData, groupedCasesChartBackgroundColours, 'Number of Cases Created over last 12 Months', true);
        }

        this.numberOfCasesInLast12Months = response.caseCount;
        this.numberOfTribunalsInLast12Months = response.tribunalCount;

    });
  }





  initChart(chart: Chart, context: CanvasRenderingContext2D, labels: string[], data: number[], backgroundColours, title: string, isCaseChart: boolean) {
    chart = new Chart(context, {
        type: 'pie',
        data: {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: backgroundColours,
                fill: true
            }],
        },
        options: {
            title: {
                display: true,
                text: title
            },
            legend: {
                display: true,
                position: 'bottom'
            },
            onClick: (e) => {
                const selectedSegment = chart.getElementsAtEvent(e);
                const selectedSegmentLabel = selectedSegment[0]._model.label;
                if (isCaseChart) {
                    this.router.navigate(['my-cases'], {queryParams: {caseTypeFilter: selectedSegmentLabel}});
                }
            }
        }
    });
  }

  getColourArrayForChartData(items: any[]) {
    let colours = this.staticColoursInSizeOrder;

    for (let i = colours.length; i < items.length; i++) {
        colours.push('#' + Math.floor(Math.random() * 16777215).toString(16));
    }

    return colours;
}

}
