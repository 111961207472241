import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { CheckListItem } from 'app/shared/models/check-list-item.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { ApiEntity } from 'app/api/models/api-entity.model';

import { UserRowModel } from './user-row.model';
import { UserRowViewModel } from './user-row-view.model';
import { UserDetailModel } from 'app/modules/configuration/user-list-page/user-detail.model';
import { MatDialog } from '@angular/material/dialog';
import { ListRequestModel } from 'app/api/models/list-request.model';

@Injectable()
export class UserListContainerService extends ListServiceBase {
  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  get ListRequest() {
    return this.listRequest as ListRequestModel;
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    this.listRequest.filters = new Array<Filter>();
    this.listRequest.filters.push(new Filter('Active', new FilterOperatorType().Equals, true));
    this.listRequest.sort.push(new SortOrder('Fullname', 'asc'));
  }

  //#region Get Endpoints

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // same endpoint being used for Create, Update & Delete
  private getCreateUpdateDeleteEndpoint(): string {
    return '/users';
  }

  // OVERRIDDEN from ListServiceBase
  protected getCreateEndpoint(): string {
    return this.getCreateUpdateDeleteEndpoint();
  }

  // OVERRIDDEN from ListServiceBase
  protected getUpdateEndpoint(itemId: number): string {
    return this.getCreateUpdateDeleteEndpoint() + '/' + itemId;
  }

  // OVERRIDDEN from ListServiceBase
  protected getDeleteEndpoint(itemId: number): string {
    return this.getCreateUpdateDeleteEndpoint() + '/' + itemId;
  }

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return '/users/' + itemId;
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return '/users/list';
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? '/users/' + itemId : '/users/blank';
  }

  // Custom endpoints
  protected getResetPasswordEndpoint(itemId: number, anonymous: boolean) {
    return anonymous === true ? '/users/' + itemId + '/anonymous-reset-password' : '/users/' + itemId + '/reset-password';
  }

  //#endregion

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // create a new ViewModel specific to this list
  // not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new UserRowViewModel(dataModel as UserRowModel);
  }

  // OVERRIDDEN from ListServiceBase
  // map to data model from the API response item for each list row item
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    return new UserRowModel(
      responseItem.id,
      responseItem.firstName,
      responseItem.surname,
      responseItem.fullname,
      responseItem.userName,
      responseItem.roles,
      responseItem.isActive
    );
  }

  //#endregion

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // map to data model from the API response item for the detail item (create/update)
  getMappedEditableItemDataModel(response: any): ApiEntity {
    // create role checklist
    const roleCheckList: CheckListItem[] = [];
    response.roles.forEach(roleItem => {
      roleCheckList.push(new CheckListItem(roleItem.id, roleItem.selected, roleItem.name));
    });

    return new UserDetailModel(
      response.id,
      response.firstNames,
      response.lastName,
      response.firstNames + ' ' + response.lastName,
      response.userName,
      roleCheckList,
      response.isActive
    );
  }

  //#endregion

  //#region update and commands

  // call base method to proces the command
  // make sure that the getCommandStubEndpoint has been overridden
  async processdeactivate(itemId: number) {
    await this.sendCommand(itemId, 'disable');
  }

  // call base method to proces the command
  // make sure that the getCommandStubEndpoint has been overridden
  async processactivate(itemId: number) {
    await this.sendCommand(itemId, 'enable');
  }

  async processActivateOnboarding(itemId: number) {
    await this.sendCommand(itemId, 'enable-onboarding');
  }



  // call base method to proces the update as a POST

  async processChangePassword(itemId: number, password: string, onboarding: boolean, anonymous: boolean) {
    await this.saveItem(itemId, { password: password, isOnboarding: onboarding }, this.getResetPasswordEndpoint(itemId, anonymous)).then(() => { });
  }

  async processChangeOwnPassword(itemId: number, password: string) {
    await this.saveItemWithoutListReference(itemId, { password: password, isOnboarding: false }, this.getResetPasswordEndpoint(itemId, false)).then(() => { });
  }

  async saveItemWithoutListReference(itemId: number, dataToUpdate: any, endpoint: string) {
    await this.setEndpoint(endpoint).save(dataToUpdate);
  }

  //#endregion
}
