import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { ActivityEmailAttachmentListItemViewModel } from '../models/activity-email-attachment-list-item-view.model';
import { ActivityEmailAttachmentListItemModel } from '../models/activity-email-attachment-list-item.model';
import { ActivityListService } from '../services/activity-list.service';

@Component({
  selector: 'activity-email-attachment-list',
  templateUrl: './activity-email-attachment-list.component.html',
  styleUrls: ['./activity-email-attachment-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]  
})
export class ActivityEmailAttachmentComponent extends AddListComponent implements OnInit {
  @Input() listData: ActivityEmailAttachmentListItemModel[];

  itemList: ActivityEmailAttachmentListItemViewModel[];

  get displayedColumns(): string[] {
    return ['name', 'actions'];
  }

  constructor(public snackBar: SnackBarService, private activityListService: ActivityListService, protected dialog: MatDialog) {
    super(activityListService);
    this.loadAfterInit = false;
  }

  ngOnInit() {
    this.itemList = [];
    this.listData.forEach((dataModel: ApiEntity) => {
      const model = new ActivityEmailAttachmentListItemViewModel(dataModel as ActivityEmailAttachmentListItemModel);
      model.itemId = dataModel.id;
      this.itemList.push(model);
    });
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        this.activityListService.downloadEmailAttachment(message.data.itemId);
        break;
      }
    }
  }


  toggleExpanded(row: ActivityEmailAttachmentListItemViewModel) {
    row.expanded = !row.expanded;
    console.debug('Email attachment item toggleExpanded() - set to: ' + row.expanded);
  }
  
}
