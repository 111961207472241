import { DefaultAsideComponent } from './default-aside.component';
import {Component, ComponentFactoryResolver, AfterViewInit, ViewChild, Input} from '@angular/core';
import {AsideContentDirective} from '../../directives/aside-content.directive';
import {DynamicComponent} from '../../../shared/base/aside/dynamic-component';
import {ComponentItem} from '../../../shared/base/aside/component-item';
import { AsideService } from 'app/layout/components/aside-container/aside.service';

@Component({
  selector: 'aside-container',
  templateUrl: './aside-container.component.html',
})
export class AsideContainerComponent implements AfterViewInit {

  @ViewChild(AsideContentDirective) asideContent: AsideContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private asideService: AsideService) { }

  ngAfterViewInit() {
    this.asideService.componentProvided.subscribe((componentItem: ComponentItem) => {
      this.loadComponent(componentItem);
    });
    this.asideService.componentUnset.subscribe(() => {
      this.loadComponent(new ComponentItem(DefaultAsideComponent, {}));
    });
  }

  loadComponent(componentItem) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);

    const viewContainerRef = this.asideContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<DynamicComponent>componentRef.instance).data = componentItem.data;
  }

}
