import { Component } from '@angular/core';
import { UsersService } from 'app/modules/user-management/services/users.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {


  constructor(protected usersService: UsersService) {
    
  }
}
