import { Component, Input, OnChanges } from '@angular/core';
import { UserTier } from 'app/shared/enumeration/user-tier';

@Component({
  selector: 'dashboard-management-information',
  templateUrl: './dashboard-management-information.component.html',
  styleUrls: ['./dashboard-management-information.component.scss']
})
export class DashboardManagementInformationComponent implements OnChanges {

    @Input() userTier: UserTier;
    showTierOneDashboard: boolean = false;
    showTierTwoDashboard: boolean = false;
    showTierThreeDashboard: boolean = false;


  constructor() {
    
  }

  ngOnChanges() {
      if (this.userTier > 0) {
          this.showTierOneDashboard = this.userTier === UserTier.TierOne;
          this.showTierTwoDashboard = this.userTier === UserTier.TierTwo;
          this.showTierThreeDashboard = this.userTier === UserTier.TierThree;
      }
  }


}
