import { Component, OnInit } from '@angular/core';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { TimeService } from 'app/modules/configuration/time/time.service';
import { TimeModel } from 'app/modules/configuration/time/time.model';
import { TimeProviderCreateUpdateComponent } from 'app/modules/configuration/time-provider/time-provider-create-update/time-provider-create-update.component';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public timeModel: TimeModel;
  public showTimeProvider = () =>
    this.timeModel ? this.timeModel.showTimeProvider : false;

  public currentDatetimeValue = () =>
      this.timeModel
      ? this.timeModel.currentDateTime
      : '';

  private refreshCurrentTime() {
    this.timeService.getCurrentTime().then((timeModel: TimeModel) => {
      this.timeModel = timeModel;
    });
  }

  ngOnInit(): void {
    this.refreshCurrentTime();
  }

  setTimeProvider() {
    this.asideService.setComponent(
      new ComponentItem(TimeProviderCreateUpdateComponent, { itemId: 0 })
    );
    const that = this;
    this.asideService.asideClose.subscribe(() => {
      that.refreshCurrentTime();
    }); 
    this.asideService.open();
  }

  constructor(
    protected usersService: UsersService,
    protected timeService: TimeService,
    private asideService: AsideService
  ) {}
}
