exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "table {\n  width: 100%;\n  table-layout: fixed; }\n\n.table-container {\n  overflow: auto;\n  width: 100%;\n  height: calc(100% - 91px); }\n\n.dashboard-table-container {\n  overflow: auto;\n  width: 100%;\n  height: calc(100% - 28px); }\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding-left: 10px; }\n\n.mat-column-actions {\n  width: 240px;\n  display: table-cell; }\n\n.mat-column-activityTypeName {\n  width: 100px; }\n\n.mat-column-createdByInitials {\n  width: 125px; }\n\n.mat-column-activityDate {\n  width: 150px; }\n\n.mat-column-summary {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.element-row.expanded {\n  border-bottom-color: transparent; }\n\ntr.detail-row {\n  height: 0 !important; }\n\n.expanded-detail {\n  overflow: hidden; }\n\n.expanded-detail-content {\n  margin: 5px 15px;\n  padding: 2px 5px;\n  border-top: 1px solid #efefef; }\n\n.table-data-row td {\n  border-bottom-width: 0 !important; }\n\n.mat-row:nth-child(odd) {\n  background-color: #ffffff; }\n\n.mat-row:nth-child(4n + 1) {\n  background-color: #efefef; }\n\ntr.mat-row:nth-child(4n + 4) {\n  background-color: #ffffff; }\n\ntr.mat-row:nth-child(4n + 2) {\n  background-color: #efefef; }\n\n.center {\n  text-align: center; }\n\n.createdbyDot {\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n  display: inline-block;\n  line-height: 30px;\n  text-align: center;\n  background-color: #d8b3e6; }\n\n.list-buttons {\n  padding-bottom: 5px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();