import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { CaseNoteFormModel } from '../models/notes-form.model';
import { NotesListService } from '../services/notes-list.service';

@Component({
  selector: 'activity-note-form',
  templateUrl: './activity-note-form.component.html',
})
export class ActivityNoteFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  viewModel = new CaseNoteFormModel();
  concurrencyErrorMessage = '';
  dataForm: FormGroup;

  startTime: Date;
  stopTime: Date;
  constructor(
    fb: FormBuilder,
    private service: NotesListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group({
      description: ['', [Validators.required, Validators.maxLength(200)]],
      content: ['', [Validators.required]],
    });
  }

  // shortcuts to controls for readability
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get content(): AbstractControl {
    return this.dataForm.controls['content'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';
    this.viewModel.levelParentId = this.data.levelParentId;
    this.viewModel.activityLevel = this.data.activityLevel;

    this.loadFormDetails();
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;

    if (this.editMode) {
      this.service.getEditableItemDetail(id).then((response: CaseNoteFormModel) => {
        this.viewModel = response;
        if (this.viewModel) {
          this.concurrencyErrorMessage = '';
          this.initialiseForm();
        }
      });
    }
  }

  initialiseForm() {
    this.dataForm.controls['description'].setValue(this.viewModel.description);
    this.dataForm.controls['content'].setValue(this.viewModel.content);
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      description: this.description.value,
      content: this.content.value,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp,
      activityLevel: this.viewModel.activityLevel,
      levelParentId: this.viewModel.levelParentId,
      publishToPortal: true,
      isPortal: true,
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      this.service.createActivityNote(updateModel).then(() => {
        this.asideService.close();
      });
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
