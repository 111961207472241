import { Component, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ConfirmModel {
  title: string,
  message: string,
  result: boolean
}

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation-modal.component.html',
})


export class ConfirmationModalComponent  {
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel) { }

    @Output() 
    onClose = new EventEmitter<ConfirmModel>(true);
  
  confirmed() {
    this.data.result = true;
    this.onClose.emit(this.data);
    this.dialogRef.close();
  }

  cancelled() {
    this.data.result = false;
    this.onClose.emit(this.data);
    this.dialogRef.close();
  }

  
}


