import { Component, EventEmitter, Output, Input } from '@angular/core';
import { SnackBarService } from 'app/shared/services/snack-bar.service';

@Component({
  selector: 'icon-action-button',
  templateUrl: './icon-action-button.component.html',
})



// Simple bootstrap icon text button with some properties bound to inputs
// Uses font awesome icons and bootstrap outline syling
// When clicked the control emits an event with the name of the associated action
export class IconActionButtonComponent {
  @Input() enabled = true;
  @Input() actionEnabled = true;
  @Input() actionDisabledMessage = 'This action is not available';
  @Input() visible = true;
  @Input() iconName = 'ban'; // can be empty
  @Input() actionName = 'unknown';
  @Input() hoverText = 'unknown';
  @Input() buttonText: string; // can be empty
  @Input() class = ''; 
  @Output() onInvokeAction = new EventEmitter<string>();

  constructor(public snackBar: SnackBarService) {}

  public invokeAction() {
    if (this.actionEnabled) {
      this.onInvokeAction.emit(this.actionName);
    } else {
      this.snackBar.info(this.actionDisabledMessage);
    }
  }

}
