export class UserPermissions {
  public isInitialised = false;
  public userName = '';
  public action = new ActionOptions();

  public tierOne = false
  public tierTwo = false
  public tierThree = false

  public markAsInitialised(userName: string) {
    this.isInitialised = true;
    this.userName = userName;
  }
}

export class UserPermissionsAction {
  public message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class ActionOptions {
  public configuration = new ConfigurationOptionGroup();
  public menu = new MenuGroup();
}

export class ConfigurationOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public manageUsers = false;
  public manageOther = false;

  public getIsEnabled(): boolean {
    return this.manageUsers;
    // || this.manageOther;
  }
}

export interface IMenuGroup {
  isEnabled: boolean;
  getIsEnabled(): boolean;
}

export class MenuGroup implements IMenuGroup {
  public isEnabled = false;
  public available: boolean;

  public getIsEnabled(): boolean {
    return true;
  }
}

export interface IOptionGroup {
  isEnabled: boolean;
  getIsEnabled(): boolean;
}
