import { MainMenuService } from './../services/main-menu.service';
import { DefaultAsideComponent } from './../components/aside-container/default-aside.component';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ResponsiveService } from './../services/responsive.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ComponentItem } from '../../shared/base/aside/component-item';
import { MatSidenav } from '../../../../node_modules/@angular/material/sidenav';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { DivisionThemeService } from '../services/division-theme.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  showMenu = false;

  constructor(
    router: Router,
    route: ActivatedRoute,
    protected usersService: UsersService,
    public mainMenuService: MainMenuService,
    public asideService: AsideService,
    public responsiveService: ResponsiveService,
    private themeService: DivisionThemeService
  ) {
    const d = router.navigateByUrl;
  }

  ngOnInit() {
    this.asideService.asideOpen.subscribe(() => {
      this.sidenav.open();
    });
    this.asideService.asideClose.subscribe(() => {
      this.sidenav.close();
    });
    this.responsiveService.resize.subscribe(() => {
      if (this.responsiveService.innerWidth <= 1023) {
        this.mainMenuService.menuVisible = false;
      } else {
        this.mainMenuService.menuVisible = true;
      }
    });
  }

  ngAfterViewInit() {
    this.asideService.setComponent(new ComponentItem(DefaultAsideComponent, {}));

    this.usersService.ensurePermissions().then(() => {
      //this.showMenu = this.usersService.userPermissions.action.menu.available;
    });
  }
}
