import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ListItemViewBaseModel } from 'app/shared/models/list-item-view-base.model';
import { ActivityListItemModel } from './activity-list-item.model';

export class ActivityListItemViewModel extends ListItemViewBaseModel<ActivityListItemModel> {
  expanded = false;
  downloadButton: IIconActionButton;


  constructor(dataModel: ActivityListItemModel) {
    super();
    this.dataModel = dataModel;

    const downloadPossible =
      dataModel.systemActivityType === SystemActivityType.DocumentUpload ||
      dataModel.systemActivityType === SystemActivityType.DocumentCreate;

      //const canDownload = mode !== CaseActivityListMode.TribunalPackSelection && downloadPossible;
      // we don't have "Mode", let's just base on on downloadPossible for now
      const canDownload = downloadPossible;

      if (canDownload) {
        this.downloadButton = {
          visible: true,
          enabled: true,
          actionEnabled: true,
          actionDisabledMessage: '',
          iconName: 'cloud_download',
          actionName: 'download',
          hoverText: 'Download the item',
          class: '',
          buttonText: '',
        };
        this.processActions.push(this.downloadButton);
      }


  }
}
