import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { InternalFileUploaderComponent } from 'app/shared/components/file-uploader/file-uploader.component';
import { formatAsString, getCurrentDate, toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { fileRequiredIf } from 'app/shared/validation/custom-validators';
import { ActivityDocumentUploadModel } from '../models/activity-document-upload.model';
import { ActivityDocumentUploadService } from '../services/activity-document-upload.service';

@Component({
  selector: 'activity-document-upload-form',
  templateUrl: './activity-document-upload-form.component.html',
  styles: [
    `
      .form-field {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    `,
  ],
})
export class ActivityDocumentUploadFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;
  fileUploaderTouched = false;

  documentTypeLookupData: any[];
  uploadEndpoint = '';
  viewModel: ActivityDocumentUploadModel;
  concurrencyErrorMessage = '';
  dataForm: FormGroup;
  maxReceivedDate: Date;

  startTime: Date;
  stopTime: Date;

  activityLevel: number;
  levelParentId: number;

  @ViewChild(InternalFileUploaderComponent) fileUploaderComponent: InternalFileUploaderComponent;

  constructor(
    fb: FormBuilder,
    private service: ActivityDocumentUploadService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group(
      {
        documentTypeId: ['', [Validators.required]],
        description: ['', [Validators.required, Validators.maxLength(200)]],
        receivedDate: ['', [Validators.required]],
      },
      {
        validator: Validators.compose([fileRequiredIf(this.isFileRequired.bind(this), this.numberOfFilesToUpload.bind(this))]),
      }
    );
  }

  fileUploadQueueChanged() {
    this.fileUploaderTouched = true;
    this.dataForm.updateValueAndValidity();
  }

  isFileRequired() {
    return this.fileUploaderTouched === true && this.editMode === false;
  }

  numberOfFilesToUpload() {
    return this.fileUploaderComponent.filesToUpload;
  }

  // shortcuts to controls for readability
  get documentTypeId(): AbstractControl {
    return this.dataForm.controls['documentTypeId'];
  }
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get receivedDate(): AbstractControl {
    return this.dataForm.controls['receivedDate'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Upload';

    this.uploadEndpoint = this.editMode ? this.service.getUpdateWithUploadEndpoint(this.data.itemId) : this.service.getUploadEndpoint();
    this.loadFormDetails();
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;

    this.maxReceivedDate = getCurrentDate();

    this.service.getEditableItemDetail(id).then((response: ActivityDocumentUploadModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.concurrencyErrorMessage = '';
        this.initialiseForm();
      }
    });
  }

  private uploadDocument(updateModel) {
    this.fileUploaderComponent.headers.push({ name: 'X-JsonData', value: JSON.stringify(updateModel) });
    this.fileUploaderComponent.onApply();
  }

  fileUploadComplete(): void {
    this.fileUploaderComponent.clearQueue();
    this.asideService.close('update');
  }

  initialiseForm() {
    this.documentTypeLookupData = this.formHelperService.createLookupData(this.viewModel.documentTypeLookupData);
    this.documentTypeId.setValue(this.viewModel.documentTypeId);
    this.description.setValue(this.viewModel.description);
    this.receivedDate.setValue(this.viewModel.receivedDate !== undefined ? this.viewModel.receivedDate : getCurrentDate().toJSON());
    this.viewModel.levelParentId = this.data.levelParentId;
    this.viewModel.activityLevel = this.data.activityLevel;

    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      documentTypeId: this.documentTypeId.value,
      description: this.description.value,
      publishToPortal: true,
      receivedDate: formatAsString(this.receivedDate.value),
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp,
      activityLevel: this.viewModel.activityLevel,
      levelParentId: this.viewModel.levelParentId,
      isPortal: true,
    };
  }

  save() {
    this.fileUploaderTouched = true;
    this.dataForm.updateValueAndValidity();

    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.fileUploaderComponent.uploader.queue.length > 0) {
        this.uploadDocument(updateModel);
      } else {
        this.service.updateWithNoUpload(this.data.itemId, updateModel).then(() => {
          this.asideService.close('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
