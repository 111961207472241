exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#container {\n  width: 400px;\n  margin: auto;\n  margin-top: 100px; }\n\n.card-info {\n  background-color: blue; }\n\n.card-error {\n  background-color: pink; }\n\n#AppInfo {\n  clear: both;\n  font-family: Roboto, sans-serif;\n  font-size: 0.7em; }\n\n.text-align-center {\n  text-align: center; }\n\nmat-form-field {\n  width: 100%; }\n\n.logo {\n  max-width: 370px;\n  width: auto;\n  height: auto; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();