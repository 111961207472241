import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

// controls must be named "password" and "confirmPassword" for this to work
export function passwordsMustMatch(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let group = control.parent;

    if (!group) {
      return null;
    }
    let password = group.controls['password'];
    let confirmPassword = group.controls['confirmPassword'];

    const checkRequired = password.value && password.value.length > 0 && confirmPassword.value && confirmPassword.value.length > 0;

    let returnVal = checkRequired ? (password.value !== confirmPassword.value ? { passwordMismatch: { value: true } } : null) : null;

    return returnVal;
  };
}

export function fileRequiredIf(conditionCallback: () => boolean, numberOfFilesCallback: () => number): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {

    var valid = conditionCallback() ? numberOfFilesCallback() > 0 : true;
    if (!valid) {
      return { fileRequired: { value: true } };
    } else {
      return null;
    }
  };
}

export function requiredIf(conditionCallback: () => boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const group = control.parent;

    if (group === undefined) {
      return null;
    }
    const valid = conditionCallback() ? control.value !== null && control.value !== '' && control.value !== undefined : true;
    if (!valid) {
      return { required: { value: true } };
    } else {
      return null;
    }
  };
}

export function dateLessThan(field: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const group = control.parent;
    if (group === undefined || control.value === '' || control.value == null) {
      return null;
    }

    const fieldToCompare = group.get(field);
    const expected = new Date(fieldToCompare.value) > new Date(control.value);
    return !expected ? { datelessthan: { value: control.value } } : null;
  };
}

export function dateGreaterThan(field: string): ValidatorFn {


  return (control: AbstractControl): { [key: string]: any } => {
    const group = control.parent;
    if (group === undefined || control.value === '' || control.value == null) {
      return null;
    }

    const fieldToCompare = group.get(field);
    const expected = new Date(fieldToCompare.value) < new Date(control.value);
    return !expected ? { 'dategreaterthan': { value: control.value } } : null;
  };

}

export function dateGreaterThanOrEqualTo(field: string): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {
    const group = control.parent;
    if (group === undefined || control.value === '' || control.value == null) {
      return null;
    }
    const fieldToCompare = group.get(field);
    const expected = new Date(fieldToCompare.value) <= new Date(control.value);
    return !expected ? { 'dategreaterthanequalto': { value: control.value } } : null;
  };
}


export function dateGreaterThanOrEqualToComparison(fieldA: string, fieldB: string): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {
    const group = control.parent;
    if (group === undefined || control.value === '' || control.value == null) {
      return null;
    }
    const fieldToCompareA = group.get(fieldA);
    const fieldToCompareB = group.get(fieldB);
    const expected = new Date(fieldToCompareA.value) <= new Date(fieldToCompareB.value);
    return !expected ? { 'dategreaterthanequaltocomparison': { value: control.value } } : null;
  };


}


