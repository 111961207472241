import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { DatabaseAuditListItemModel } from './database-audit-list-item.model';

export class DatabaseAuditListItemViewModel extends ActionableItemState {
  editButton: IIconActionButton;

  constructor(dataModel: DatabaseAuditListItemModel) {
    super();

    this.dataModel = dataModel;

    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'info',
      actionName: 'info',
      hoverText: 'Edit the Audit Item Type',
      class: '',
      buttonText: ''
    };

    this.processActions.push(this.editButton);
  }
}
