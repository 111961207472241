import { Injectable, ViewContainerRef } from "@angular/core";
import { ApiDependenciesService } from "app/api/services/api-dependencies.service";
import { DataService } from "app/shared/services/data.service";

@Injectable()
export class DivisionThemeService extends DataService<any> {
  divisionName: string;
  
    constructor(dependencies: ApiDependenciesService) {
    super(dependencies);
  }


}
