import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';
import { CookieService } from 'ng2-cookies';
import { CookieService as KaleidaCookieService } from 'app/shared/services/cookie.service';
import { LoggingService } from 'app/shared/services/log.service';

import { ApplicationService } from './services/application.service';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { IconActionButtonComponent } from 'app/shared/components/icon-action-button/icon-action-button.component';
import { IconActionButtonListComponent } from 'app/shared/components/icon-action-button-list/icon-action-button-list.component';
import { ListFilterComponent } from 'app/shared/components/list-filter/list-filter.component';
import { ListFilterInlineComponent } from 'app/shared/components/list-filter/list-filter-inline.component';
import { PageTitleComponent } from 'app/shared/components/page-title/page-title.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { LogDataService } from 'app/shared/services/log.data.service';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SnackBarService } from './services/snack-bar.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ListPageJumperComponent } from './components/list-page-jumper/list-page-jumper.component';
import { SimpleFileUploadComponent } from './components/simple-file-upload/simple-file-upload.component';
import { InternalFileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActiveInactivePipe } from './pipes/active-inactive.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { SharedRoutesModule } from './shared-routes/shared-routes.module';
import { RouterModule } from '@angular/router';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ToBeImplementedComponent } from './components/to-be-implemented/to-be-implemented.component';
import { UrlLinkPipe } from './pipes/url-link.pipe';
import { EmailLinkPipe } from './pipes/email-link.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { KaleidaPopoverDirective } from './components/popover/popover.directive';
import { KaleidaPopoverComponent } from './components/popover/popover.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ConcurrencyErrorComponent } from './components/concurrency-error/concurrency-error.component';
import { IsSystemPipe } from './pipes/IsSystem.pipe';
import { YesBlankPipe } from './pipes/yes-blank.pipe';
import { PostcodeLookupComponent } from './components/postcode-lookup/postcode-lookup.component';
import { CompanyLookupComponent } from './components/company-lookup/company-lookup.component';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

@NgModule({
  imports: [CommonModule, BlockUIModule, FormsModule, ReactiveFormsModule, SharedRoutesModule, OverlayModule, RouterModule, FileUploadModule, FlexLayoutModule, AppMaterialModule],
  declarations: [
    ConfirmationModalComponent,
    IconActionButtonComponent,
    IconActionButtonListComponent,
    ListFilterComponent,
    ListFilterInlineComponent,
    PageTitleComponent,
    SnackBarComponent,
    ListPageJumperComponent,
    SimpleFileUploadComponent,
    InternalFileUploaderComponent,
    ActiveInactivePipe,
    YesNoPipe,
    YesBlankPipe,
    IsSystemPipe,
    UrlLinkPipe,
    EmailLinkPipe,
    SafeHtmlPipe,
    NoResultsComponent,
    ToBeImplementedComponent,
    BreadcrumbComponent,
    KaleidaPopoverDirective,
    KaleidaPopoverComponent,
    ConcurrencyErrorComponent,
    PostcodeLookupComponent,
    CompanyLookupComponent,
    WarningBoxComponent,
  
  ],
  entryComponents: [ConfirmationModalComponent, SnackBarComponent, KaleidaPopoverComponent],
  exports: [
    BlockUIModule,
    ConfirmationModalComponent,
    IconActionButtonComponent,
    IconActionButtonListComponent,
    ListFilterComponent,
    ListFilterInlineComponent,
    PageTitleComponent,
    SnackBarComponent,
    ListPageJumperComponent,
    SimpleFileUploadComponent,
    InternalFileUploaderComponent,
    FlexLayoutModule,
    ActiveInactivePipe,
    YesNoPipe,
    YesBlankPipe,
    IsSystemPipe,
    UrlLinkPipe,
    EmailLinkPipe,
    SafeHtmlPipe,
    NoResultsComponent,
    ToBeImplementedComponent,
    BreadcrumbComponent,
    KaleidaPopoverDirective,
    ConcurrencyErrorComponent,
    PostcodeLookupComponent,
    CompanyLookupComponent,
    WarningBoxComponent,
    
  ],
  providers: [
    SnackBarService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { verticalPosition: 'top', horizontalPosition: 'right' },
    },
    KaleidaCookieService,
    CookieService,
    // Providers needed for asideService.setComponent() calls! These should only really be needed in the offline module.
    ApplicationService,
    { provide: 'windowObject', useValue: window },
    { provide: 'navigatorObject', useValue: navigator },
    ReactiveFormHelperService,
    LoggingService,
    LogDataService,
  ],
})
export class SharedModule {}
