import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, AfterViewInit, Component, Injector, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityDocumentUploadFormComponent } from '../activity-document-upload-form/activity-document-upload-form.component';
import { ActivityNoteFormComponent } from '../activity-note-form/activity-note-form.component';
import CaseActivityHelper from '../helpers/case-activity-helper';
import { ActivityListItemViewModel } from '../models/activity-list-item-view.model';
import { CaseActivityListMode } from '../models/CaseActivityListMode';
import { ActivityListService } from '../services/activity-list.service';

@Component({
  selector: 'activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityListComponent extends AddListComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit, AfterContentInit {
  @Input() listMode = CaseActivityListMode.History;
  @Input() levelParentId: number;
  @Input() activityLevel: number;
  @Input() companyId: number;

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  showButtons: boolean;

  get displayedColumns(): string[] {
    return ['activityTypeName', 'createdByInitials', 'summary', 'actions', 'activityDate'];
  }

  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private activityListService: ActivityListService,
    private asideService: AsideService,
    public snackBar: SnackBarService,
    protected dialog: MatDialog,
    private injector: Injector
  ) {
    super(activityListService);
    this.loadAfterInit = false;  
  }

  ngAfterContentInit() {
    if (this.activityLevel !== ActivityLevel.PortalDashboard) {
      this.showButtons = true;    
    } else {
      this.showButtons = false;
    }
  }

  iconFromActivityType(systemActivityType: SystemActivityType): string {
    return CaseActivityHelper.iconFromActivityType(systemActivityType);
  }

  get noItemsSelected(): boolean {
    return this.activityListService.getSelectedItemIds().length === 0;
  }

  @ViewChild(MatSort)
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) {
        this.matSortSubscribed.unsubscribe();
      }
      this.matSortSubscribed = this.sort.sortChange.subscribe((e) => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator)
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) {
        this.matPaginatorSubscribed.unsubscribe();
      }

      this.matPaginatorSubscribed = this.paginator.page.subscribe((e) => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  ngOnChanges(): void {
    this.activityListService.activityLevel = this.activityLevel;

    if (this.levelParentId) {
      this.activityListService.levelParentId = this.levelParentId;
    }
    this.activityListService.reload();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.asideService.asideClose.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.activityListService.levelParentId = this.levelParentId;

      this.activityListService.reload();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column: string, direction: string) {
    this.service.setSort(column, direction);
  }

  addNew(activityTypeName: string) {
    switch (activityTypeName) {
      case 'Note':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityNoteFormComponent, { itemId: 0, levelParentId: this.levelParentId, activityLevel: this.activityLevel }),
          this.injector
        );
        break;
      case 'Upload Document':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentUploadFormComponent, {
            itemId: 0,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
    }
  }

  isRowExpandable(row: ActivityListItemViewModel) {
    const hasDetail =
      row.dataModel.systemActivityType === SystemActivityType.EmailInbound ||
      row.dataModel.systemActivityType === SystemActivityType.EmailOutbound ||
      row.dataModel.systemActivityType === SystemActivityType.Note ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentPackCreate ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentCreate ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentUpload;
    return hasDetail;
  }

  toggleExpanded(row: ActivityListItemViewModel) {
    this.activityListService.divisionId = +row.dataModel.divisionId;
    const hasDetail = this.isRowExpandable(row);
    row.expanded = hasDetail && !row.expanded;
  }

  ShowDefaultContents(row: ActivityListItemViewModel): boolean {
    return !(this.ShowDocumentPackContents(row) || this.ShowInboundEmailContents(row) || this.ShowFilePreview(row));
  }

  ShowDocumentPackContents(row: ActivityListItemViewModel): boolean {
    return row.dataModel.systemActivityType === SystemActivityType.DocumentPackCreate;
  }

  ShowInboundEmailContents(row: ActivityListItemViewModel): boolean {
    return row.dataModel.systemActivityType === SystemActivityType.EmailInbound;
  }

  ShowFilePreview(row: ActivityListItemViewModel): boolean {
    return (
      row.dataModel.systemActivityType === SystemActivityType.DocumentCreate ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentUpload
    );
  }

  convertString(source): string {
    if (!source) {
      return '';
    }
    return StringUtilities.translateCR(source);
  }
  expandCollapseAll(collapsed: boolean) {
    this.activityListService.itemList.forEach((row) => {
      this.setRowExpanded(row, collapsed);
    });
  }

  setRowExpanded(row: ActivityListItemViewModel, expanded: boolean) {
    const hasDetail = this.isRowExpandable(row);
    row.expanded = hasDetail && expanded;
  }

  get displayDownloadTribunalPack(): boolean {
    return this.listMode === CaseActivityListMode.TribunalPackContents && this.service.itemsCount > 0;
  }

  get isTribunalTab(): boolean {
    return this.listMode !== CaseActivityListMode.History;
  }

  get downloadTribunalPackButtonTitle(): string {
    return 'Download Pack';
  }

  downloadTribunalPack() {
    this.activityListService.downloadPack().then(() => {});
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.activityListService.downloadFile(caseActivity.dataModel.uniqueIdentifier);
        break;
      }

      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }

}
