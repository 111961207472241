import { Component } from '@angular/core';
import { UsersService } from 'app/modules/user-management/services/users.service';

@Component({
  selector: 'dashboard-card-link-list',
  templateUrl: './dashboard-card-link-list.component.html',
  styleUrls: ['./dashboard-card-link-list.component.scss']
})
export class DashboardCardLinkListComponent {


  constructor(protected usersService: UsersService,) {
    
  }
}
