export class Constants {

  public static Offline = class {

    public static KeyOfflineStatus = 'dfportal_offline-status';
    public static IsOffline = 'offline';
    public static IsOnline = 'online';

    public static MaxFileSystemMb = 500 * 1024 * 1024;
    public static KeyInspectionPrefix = 'inspection-';

    public static KeyDevelopmentInfoPrefix = 'development-';

    public static KeyPendingInspectionPollRequests = 'pending-inspection-poll-requests';
    public static PollPauseMilliseconds = 5 * 1000;
    public static PollPauseLongerMilliseconds = 120 * 1000;

    public static KeyPendingInspectionSyncRequests = 'pending-inspection-sync-requests';
    public static SyncPauseMilliseconds = 5 * 1000;
    public static SyncPauseLongerMilliseconds = 120 * 1000;
  }

  public static LocalStorage = class {

    public static DatabaseName = 'WH-LocalStorage';
    public static Version = 1.0;
    public static Description = 'Database used by the application to store information needed for offline working';
    public static Size = 52428800;
    public static PurgeDays = 14;
  }

  public static Audit = class {

    public static KeyAuditEntryPrefix = 'audit-';
    public static KeyAuditEntryFrequency = 'DD-MMM-YYYY-HH-mm';
  }

  public static FileSystem = class {

    public static PurgeDays = 60;

    public static FileError = class {
      public static QUOTA_EXCEEDED_ERR = 'QuotaExceededError';
      public static NOT_FOUND_ERR = 'NOT_FOUND_ERR';
      public static SECURITY_ERR = 'SECURITY_ERR';
      public static INVALID_MODIFICATION_ERR = 'INVALID_MODIFICATION_ERR';
      public static INVALID_STATE_ERR = 'INVALID_STATE_ERR';
    }

    public static FileReaderReadyState = class {
      public static EMPTY = 0;
      public static LOADING = 1;
      public static DONE = 2;
    }
  }

  public static Cookies = class {

    public static KeyAccessToken = 'dfportal_accessToken';
    public static KeyLoggedInUserId = 'dfportal_loggedInUserId';
    public static KeyLoggedInUserName = 'dfportal_loggedInUserName';
    public static KeyLoggedInFullName = 'dfportal_loggedInFullName';
    public static KeyUiState = 'dfportal_uiState';
    public static KeyDeviceId = 'dfportal_deviceId';
    public static KeyLoggedInUserIsInternal = 'dfportal_loggedInUserIsInternal';
    public static KeyLoggedInUserExtension = 'dfportal_loggedInUserExtension';
    
    
    
  }

  public static Evidence = class {

    public static MaxThumbnailWidth = 200;
  }
}
