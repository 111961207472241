import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { Division } from 'app/shared/enumeration/division';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { CompanyContactFormModel } from '../models/company-contact-form.model';
import { CompanyContactListItemViewModel } from '../models/company-contact-list-item-view.model';
import { CompanyContactListItemModel } from '../models/company-contact-list-item.model';

@Injectable()
export class CompanyContactListService extends ListServiceBase {
  companyId: number;
  divisionId = Division.Supportis;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    return this.companyId ? `/divisions/${this.divisionId}/companies/${this.companyId}/contacts/my-contacts` 
    : `/divisions/${this.divisionId}/contacts/my-contacts`;
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getDeleteEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }
  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/list';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new CompanyContactListItemViewModel(dataModel as CompanyContactListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    let model: CompanyContactListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    let model: CompanyContactFormModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }
  // call base method to proces the command
  // make sure that the getCommandStubEndpoint has been overridden
  async processdeactivate(itemId: number) {
    await this.sendCommand(itemId, 'disable');
  }

  // call base method to proces the command
  // make sure that the getCommandStubEndpoint has been overridden
  async processactivate(itemId: number) {
    await this.sendCommand(itemId, 'enable');
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async makePrimary(itemId: number) {
    await this.sendCommand(itemId, 'make-primary');
  }

  async isEmailUniqueInContacts(id: number, value: any): Promise<boolean> {
    let result = true;

    const endpoint = this.baseEndpointWithId(id) + '/is-contact-email-unique?email=' + value;

    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: boolean) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  async isEmailUniqueInUsers(id: number, value: any): Promise<boolean> {
    let result = true;

    const endpoint = this.baseEndpointWithId(id) + '/is-user-email-unique?email=' + value;

    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: boolean) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  async createUserAccount(itemId: number) {
    await this.sendCommand(itemId, 'create-user-account');
  }

  async activateToggle(itemId: number) {
    await this.sendCommand(itemId, 'activate-toggle');  
  }

  async isNameUnique(firstName: string, surname: string): Promise<boolean> {
    let result = true;

    const endpoint = `${this.baseEndpoint}/is-contact-name-on-case?firstName=${firstName}&lastName=${surname}`;

    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: boolean) => {
        result = response;
      });

    return Promise.resolve(result);
  }
}
