import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuardService } from 'app/core/security/guards/app-auth-guard.service';
import { LayoutComponent } from 'app/layout/layout-component/layout.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { WelcomeComponent } from '../components/welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: LayoutComponent,
    canActivate: [AppAuthGuardService],
    children: [
      {
        path: '',
        loadChildren: 'app/modules/configuration/configuration.module#ConfigurationModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/customer/customer.module#CustomerModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/contacts/contacts.module#ContactsModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/activity/activities.module#ActivitiesModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/service-agreements/service-agreements.module#ServiceAgreementModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/my-cases/my-cases.module#MyCasesModule', // async routing syntax
      },      
      {
        path: '',
        loadChildren: 'app/modules/my-services/my-services.module#MyServicesModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/my-resources/my-resources.module#MyResourcesModule', // async routing syntax
      },
      {
        path: '',
        loadChildren: 'app/modules/my-documents/my-documents.module#MyDocumentsModule', // async routing syntax
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule],
})
export class LayoutRoutesModule {}
