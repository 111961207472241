import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { CookieService } from 'app/shared/services/cookie.service';
import { Router } from '@angular/router';
import { Constants } from 'app/core/models/constants';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { AuthService } from 'app/core/security/auth/auth.service';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { UserResetPasswordComponent } from 'app/layout/components/user-reset-password/user-reset-password.component';
import { UsersService } from 'app/modules/user-management/services/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedInUserName: string;
  loggedInFullName: string;

  isOnline: boolean;

  @Output() toggleMenuEvent = new EventEmitter();

  get isAuthenticated(): boolean {
    return this.auth.isLoggedIn();
  }

  constructor(
    protected usersService: UsersService,
    private cookieService: CookieService,
    private router: Router,
    private apiDepenencies: ApiDependenciesService,
    private auth: AuthService,
    private asideService: AsideService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.usersService.ensurePermissions().then(() => {});
    this.loggedInUserName = this.cookieService.getLoggedInUserName();
    this.loggedInFullName = this.cookieService.getLoggedInFullName();
  }

  logout(): void {
    this.cookieService.delete(Constants.Cookies.KeyAccessToken);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserId);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserName);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInFullName);

    this.auth.logout();

    this.router.navigateByUrl('/login');
  }

  resetPassword(): void {
    const cookieValue = this.apiDepenencies.cookieService.get(Constants.Cookies.KeyLoggedInUserId);
    const loggedInUerId = +cookieValue;
    const userId: number = this.auth.loggedInUserId && this.auth.loggedInUserId !== -1 ? this.auth.loggedInUserId : loggedInUerId;

    this.asideService.setComponent(new ComponentItem(UserResetPasswordComponent, { itemId: userId, resetOwnPassword: true }));
    this.asideService.open();

    this.asideService.asideClose.subscribe(data => {
      if (data !== 'cancel') {
        this.logout();
      }
    });
  }

  navigateToUserManagement() {
    this.router.navigate(['configuration/users']);
  }

  toggleMenu() {
    this.toggleMenuEvent.emit();
  }
}
