export class LookupList {
  constructor(
    public name: string = '',
    public defaultId: number = null,
    public items: LookupItem[] = []) {
  }
}

export class LookupItem {
  constructor(
    public id: number = null, 
    public name: string = '',
    public data: any = null, 
    public subLists: LookupList[] = []) {
  }
}

export class ToggleList {
  constructor(
    public name: string = '',
    public items: ToggleItem[] = []) {
  }
}

export class ToggleItem {
  constructor(
    public id: number = null, 
    public name: string = '', 
    public selected: boolean = false,
    public readOnly: boolean = false,
    public readOnlyReasons: string[] = [],
    public subLists: ToggleList[] = []) {
  }

}

export class ToggleTreeItem {
  constructor(
    public id: number = null, 
    public name: string = '', 
    public selected: boolean = false,
    public children: ToggleTreeItem[] = []) {
  }
}

