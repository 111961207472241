import { Injectable } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ActivateUserModel } from './activate-user.model';

@Injectable()
export class ActivateUserService extends DataService<any> {
  constructor(dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  async getActivateUserData(guid: string): Promise<ActivateUserModel> {
    let model: ActivateUserModel;
    await this.setEndpoint('/divisions/3/single-use-actions/' + guid + '/activate-account')
      .getSingle()
      .then((response) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async resendExistingDetails(guid: string): Promise<ActivateUserModel> {
    let model: ActivateUserModel;
    await this.setEndpoint('/divisions/3/single-use-actions/' + guid + '/resend-existing?target=2')
      .save()
      .then((response) => {
        model = response;
      });
    return Promise.resolve(model);
  }
}
