exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sub-menu {\n  margin-left: 20px; }\n\n.nav-list-container {\n  margin-top: 20px; }\n\n.mat-list-item {\n  color: #29379d;\n  font-weight: 500 !important;\n  height: 32px !important; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();