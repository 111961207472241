import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { RegexValidationStrings } from 'app/shared/validation/reg-ex-validation-constants';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CompanyContactFormModel } from '../models/company-contact-form.model';
import { CompanyContactListService } from '../services/company-contact-list.service';

@Component({
  selector: 'company-contact-form',
  templateUrl: './company-contact-form.component.html',
})
export class CompanyContactFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  addressIdLookupData: any[];
  tierLookupData: any[];
  warningMessage: string;

  viewModel: CompanyContactFormModel;

  dataForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private service: CompanyContactListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog,
    private usersService: UsersService
  ) {
    this.dataForm = fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      jobTitle: ['', [Validators.required, Validators.maxLength(100)]],
      email: [
        '',
        [Validators.required, Validators.pattern(RegexValidationStrings.EMAIL), Validators.maxLength(100)],
        [this.uniqueEmailInContactsValidator(), this.uniqueEmailInUsersValidator()],
      ],
      addressId: ['', [Validators.required]],
      userTier: ['', [Validators.required]],
    });
  }

  uniqueEmailInContactsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      // id must be 0 if we are creating a new entity
      const id = this.data && this.data.itemId ? this.data.itemId : 0;
      const debounceTime = 250;
      return timer(debounceTime).pipe(
        switchMap(() =>
          this.service.isEmailUniqueInContacts(id, control.value).then((retval) => {
            return retval === false ? { notUniqueInContacts: true } : null;
          })
        )
      );
    };
  }

  uniqueEmailInUsersValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      // id must be 0 if we are creating a new entity
      const id = this.data && this.data.itemId ? this.data.itemId : 0;
      const debounceTime = 250;
      return timer(debounceTime).pipe(
        switchMap(() =>
          this.service.isEmailUniqueInUsers(id, control.value).then((retval) => {
            return retval === false ? { notUniqueInUsers: true } : null;
          })
        )
      );
    };
  }

  private subscribeToChanges() {
    this.firstName.valueChanges.subscribe(() => {
      this.isNameUnique();
    });

    this.lastName.valueChanges.subscribe(() => {
      this.isNameUnique();
    });
  }

  isNameUnique() {
    if (this.firstName.value && this.lastName.value) {
      this.service.isNameUnique(this.firstName.value, this.lastName.value).then((response) => {
        debugger;
        if (response !== true) {
          this.warningMessage = 'This name matches the name on a Case, are you sure you wish to continue?';
        } else {
          this.warningMessage = '';
        }
      });
    } else {
      this.warningMessage = '';
    }

  }

  // shortcuts to controls for readability
  get firstName(): AbstractControl {
    return this.dataForm.controls['firstName'];
  }
  get lastName(): AbstractControl {
    return this.dataForm.controls['lastName'];
  }
  get jobTitle(): AbstractControl {
    return this.dataForm.controls['jobTitle'];
  }
  get email(): AbstractControl {
    return this.dataForm.controls['email'];
  }
  get addressId(): AbstractControl {
    return this.dataForm.controls['addressId'];
  }
  get userTier(): AbstractControl {
    return this.dataForm.controls['userTier'];
  }
  get userCanEditEmail(): AbstractControl {
    return this.dataForm.controls['userCanEditEmail'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';

    const id: number = this.editMode ? this.data.itemId : null;

    this.service.getEditableItemDetail(id).then((response: CompanyContactFormModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.dataForm.controls['firstName'].setValue(this.viewModel.firstName);
    this.dataForm.controls['lastName'].setValue(this.viewModel.lastName);
    this.dataForm.controls['jobTitle'].setValue(this.viewModel.jobTitle);
    this.dataForm.controls['email'].setValue(this.viewModel.email);
    this.dataForm.controls['addressId'].setValue(this.viewModel.addressId);
    this.dataForm.controls['userTier'].setValue(this.viewModel.userTier);

    this.addressIdLookupData = this.formHelperService.createLookupData(this.viewModel.addressIdLookupData);
    this.tierLookupData = this.formHelperService.createLookupData(this.viewModel.tierLookupData);

    // if (this.viewModel.userCanEditEmail || !this.viewModel.id) {
    //   this.email.enable();
    // } else {
    //   this.email.disable();
    // }
    this.subscribeToChanges();
    this.isNameUnique();
  }

  private createUpdateModel() {
    return {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      jobTitle: this.jobTitle.value,
      email: this.email.value,
      accessToClientPortal: true,
      addressId: this.addressId.value,
      userTier: this.userTier.value
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateItem(this.data.itemId, updateModel).then(() => {
          this.asideService.close('update');
        });
      } else {
        this.service.addNew(updateModel).then(() => {
          this.asideService.close('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }

  get allowClientPortalControl(): boolean {
    return !this.editMode && this.isUserAdmin === true;
  }

  get isUserAdmin(): boolean {
    return true;
    //return this.usersService.userPermissions.action.contact.allowUserToUpdateClientPortalAccess;
  }

  //#endregion
}
